export const DEFAULT_UNDEFINED_KEY = 'UNDEFINED'
export const DEFAULT_LASTNAMETYPE_KEY = 'OWN'
export const DEFAULT_CIVILSTATETYPE_KEY = 'NOT_MARRIED'
export const DEFAULT_TIME_FRAME = 'FULL_DAY'
export const DEFAULT_DAY_DEFINITION = 'NOT_WORKED'
export const DEFAULT_FULL_DAY = 'FULL_DAY'
export const DEFAULT_BASED_ON_WORKSCHEDULE = 'BASED_ON_WORK_SCHEDULE'
export const DEFAULT_DATE = '0001-01-01'
export const DEFAULT_CURRENCY = 'EUR'
export const DEFAULT_PRIVATELY_INSURED = 'PRIVATELY_INSURED'
export const DEFAULT_PARENT_STATUS = 'CHILDLESS'
export const DEFAULT_MAIN_OCCUPATION = 'MAIN_OCCUPATION'
export const DEFAULT_PAYROLLCODE_TYPE = 'ABSENCE'
export const DEFAULT_MONTH_VIEW = 'month'
export const DEFAULT_AGENDA_VIEW = 'agenda'
export const DEFAULT_PAYROLLCODE_COLOR = '#155A80'
export const DEFAULT_LOCALE = 'en-GB'
export const DEFAULT_SALARY_TYPE = 'VARIABLE'
export const DEFAULT_PAYPERIOD = 'MONTHLY'

export const OrderByAddedOnFolders = ['PAYSLIPS', 'PAYROLL_CLOSURE']

// UI
export const DEFAULT_FLYIN_WIDTH = '25rem'
export const DEFAULT_FLYIN_WIDTH_SMALL = '18rem'
export const DEFAULT_FLYIN_WIDTH_LARGE = '30rem'

export enum BOOLEAN_STRING_KEYS {
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

export enum VALUE_TYPE_KEYS {
  AMOUNT = 'AMOUNT',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  PARAMETER = 'PARAMETER',
  PROVIDED = 'PROVIDED',
  DEFAULT = 'AMOUNT'
}
// Calculated data
export enum CALCULATED_DATA_VALUE_TYPE_KEYS {
  NUMBER = 'NUMBER',
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
  DAYS = 'DAYS',
  HOURS = 'HOURS'
}

// PARTNERS
export enum PARTNER_CONFIG_KEYS {
  DE_BUROPARTNER = 'DE_BUROPARTNER',
  DE_GENERIC = 'DE_GENERIC',
  NL_GENERIC = 'NL_GENERIC',
  NL_PHOCUS = 'NL_PHOCUS',
  IT_GENERIC = 'IT_GENERIC',
  IT_LDP = 'IT_LDP',
  CZ_GENERIC = 'CZ_GENERIC',
  CZ_ASBGROUP = 'CZ_ASBGROUP',
  ES_ASEC = 'ES_ASEC',
  ES_GENERIC = 'ES_GENERIC'
}

export const partners = Object.keys(PARTNER_CONFIG_KEYS)

export enum PARTNER_LIST_IDENTIFIERS {
  ES_EMPLOYMENT_STATUS = 'ES_EMPLOYMENT_STATUS',
  ES_EMPLOYMENT_TYPE = 'ES_EMPLOYMENT_TYPE',
  ES_SOCIAL_SECURITY_CATEGORY = 'ES_SOCIAL_SECURITY_CATEGORY'
}

//Request policies
export enum ABSENCE_TYPE_KEYS {
  DAYS = 'DAYS',
  HOURS = 'HOURS'
}

export enum CANCELLATION_ALLOWED_TYPE_KEYS {
  ALLOWED = 'ALLOWED',
  ALLOWED_IN_PRESENT = 'ALLOWED_IN_PRESENT',
  NOT_ALLOWED = 'NOT_ALLOWED'
}

export enum REQUEST_POLICY_TIME_TYPE_KEYS {
  BASED_ON_WORKSCHEDULE = 'BASED_ON_WORKSCHEDULE',
  ADDITIONAL = 'ADDITIONAL'
}

export enum REQUEST_MINIMUM_TYPE_KEYS {
  FULL_DAY = 'FULL_DAY',
  LESS_THAN_FULL_DAY = 'LESS_THAN_FULL_DAY'
}

export const DEFAULT_ABSENCE_TYPE = ABSENCE_TYPE_KEYS.DAYS
export const DEFAULT_REQUEST_MINIMUM_TYPE = REQUEST_MINIMUM_TYPE_KEYS.LESS_THAN_FULL_DAY
export const DEFAULT_OBFUSCATED_TYPE = 'ABSENT'
export const DEFAULT_REQUEST_POLICY_TIME_TYPE = REQUEST_POLICY_TIME_TYPE_KEYS.BASED_ON_WORKSCHEDULE
export const DEFAULT_CANCELLATION_ALLOWED_TYPE = CANCELLATION_ALLOWED_TYPE_KEYS.ALLOWED_IN_PRESENT

//Events
export const EVENT_BIRTHDAY = 'BIRTHDAY'
export const EVENT_ANNIVERSARY = 'ANNIVERSARY'
export const EVENT_PROBATION = 'PROBATION'
export const EVENT_STARTERS = 'STARTERS'
export const EVENT_CAR = 'CAR'
export const EVENT_LEAVERS = 'LEAVERS'

export enum PROCESS_TYPE {
  PAYROLL_CLOSURE = 'payrollclosure',
  PAYCOMPONENT_UPDATE = 'paycomponentupdate'
}

export enum PROCESS_PAYROLLCLOSURE_PARAMETER {
  ORIGINAL_CORRECTIONS_PERSONCONTRACT = 'ORIGINAL_CORRECTIONS_PERSONCONTRACT',
  CORRECTIONS_PERSONCONTRACT = 'CORRECTIONS_PERSONCONTRACT',
  PERSONCONTRACT = 'PERSONCONTRACT'
}

export enum FEATURE {
  AICOMPANION = 'aiCompanion'
}

export enum ROLE {
  TENANTADMIN = 'TENANTADMIN',
  ADMIN = 'ADMIN',
  HREMPLOYEE = 'HREMPLOYEE',
  HRMANAGER = 'HRMANAGER',
  SELFSERVICEUSER = 'SELFSERVICEUSER',
  MANAGEUSERSANDPOLICIES = 'MANAGEUSERSANDPOLICIES',
  IMPORTDATA = 'IMPORTDATA',
  MANAGELISTS = 'MANAGELISTS',
  CREATEPERSON = 'CREATEPERSON',
  AICOMPANION = 'AICOMPANION'
}

export enum ERRORTYPE {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}

export enum STARTERS_LEAVERS_PERIOD_TYPES {
  YOY = 'Year on Year',
  YTD = 'Year to Date',
  LAST_MONTH = 'Last month',
  CURRENT_MONTH = 'Current month',
  NEXT_MONTH = 'Next month'
}

//RequestPolicyRights
export const REQUEST_RIGHT_APPROVER = 'APPROVER'
export const REQUEST_RIGHT_EDITOR = 'EDITOR'
export const REQUEST_RIGHT_INFORMER = 'INFORMER'

//Manual event
export enum MANUAL_EVENT_TYPE {
  REMOVE_BALANCE = 'REMOVE_BALANCE',
  SET_BALANCE = 'SET_BALANCE'
}

export enum ABSENCE_REQUEST_STATUS_TYPE {
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED'
}

// PAYROLLCLOSURE CONTRACT PERSON TAB
export const CONTRACT = 'CONTRACT'
export const PERSON = 'PERSON'
export const CIVIL_STATE = 'CIVIL_STATE'
export const FAMILY = 'FAMILY_MEMBER'
export const FAMILY_STATE = 'FAMILY_MEMBER_STATE'
export const OFFICIAL_ADDRESS = 'OFFICIAL_ADDRESS'
export const RESIDENTIAL_ADDRESS = 'RESIDENTIAL_ADDRESS'
export const COMPANY_CAR = 'COMPANY_CAR'
export const EMPTY_STATE = 'EMPTY_STATE'

// UPDATE OPERATION TYPES
export enum UPDATE_OPERATION_TYPE {
  MANUAL_INPUT = 'MANUAL_INPUT_BASED_ON_REFERENCE_PERIOD_VALUE',
  MULTIPLICATION = 'MULTIPLICATION_WITH_VALUE',
  VALUE = 'ADD_VALUE'
}

export enum PROCESSING_IMPORTJOB_TYPES {
  ADDRESSES = 'ADDRESSES',
  CALCULATED_DATA = 'CALCULATED_DATA',
  CIVIL_STATES = 'CIVIL_STATES',
  COMPANY_CARS = 'COMPANY_CARS',
  CONTRACTS = 'CONTRACTS',
  MANUAL_EVENTS = 'MANUAL_EVENTS',
  PAYCOMPONENTS = 'PAYCOMPONENTS',
  PAYROLL_CODES = 'PAYROLL_CODES',
  PEOPLE = 'PEOPLE',
  PERSONAL_CALENDAR_DAYS = 'PERSONAL_CALENDAR_DAYS'
}

export enum DOCUMENT_ACCESSLEVELTYPE_KEYS {
  SYSTEM_CALCULATED = 'SYSTEM_CALCULATED',
  NO_ACCESS = 'NO_ACCESS',
  CAN_VIEW = 'CAN_VIEW',
  CAN_EDIT = 'CAN_EDIT'
}

// PRODUCTIVITY ANALYTICS
export enum PRODUCTIVITYANALYTICS_VIEWPERIODS {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR'
}

export enum PAYPERIODTYPES {
  WEEKLY = 'WEEKLY',
  BI_MONTHLY = 'BI_MONTHLY',
  MONTHLY = 'MONTHLY'
}

export const NUMBEROFWEEKS_PAYPERIOD = [
  { key: '1', value: '1', active: true },
  { key: '2', value: '2', active: true },
  { key: '4', value: '4', active: true }
]
