import { Box, Button } from '@mui/material'
import { Configuration, DetailPageBaseQueryParams, HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGetAllEmployerDocumentTypesByEmployerIdQuery, useGetEmployerByIdQuery } from '@epix-web-apps/core'
import { FolderList } from '../../components/document-components'
import AddIcon from '@mui/icons-material/Add'
import { AddEditCompanyDocumenttype } from '../../components/document-components/add-edit-company-documenttype'
import { EditAccessCompanyDocumenttype } from '../../components/document-components/edit-access-company-documenttype'

/* eslint-disable-next-line */
export interface CompanyDocumentTypesPageProps {}

export type CompanyDocumentTypesDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function CompanyDocumentTypesPage(props: CompanyDocumentTypesPageProps) {
  const params = useParams<CompanyDocumentTypesDetailPageParams>()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: params.employerid || ''
  })
  const { data: getAllEmployerDocumentTypes, refetch } = useGetAllEmployerDocumentTypesByEmployerIdQuery({
    id: params.employerid || ''
  })

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={`${t('companydocumenttypespage.title')} - ${getEmployerById?.employerById.companyName}`}
        />
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddEditCompanyDocumenttype />,
              callbackAfterClose: () => refetch
            })
          }
        >
          <AddIcon />
          {t('companydocumenttypespage.button.newdocumenttype')}
        </Button>
      </Box>
      <FolderList
        folders={getAllEmployerDocumentTypes?.allEmployerDocumentTypesByEmployerId || []}
        employerId={params.employerid || ''}
        editFlyin={(documentTypeId?: string) => <AddEditCompanyDocumenttype documentTypeId={documentTypeId} />}
        editAccessFlyin={(documentTypeId?: string) => <EditAccessCompanyDocumenttype documentTypeId={documentTypeId} />}
        onChangesSaved={refetch}
      />
    </>
  )
}

export default CompanyDocumentTypesPage
