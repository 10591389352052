import { Grid, Button, Typography, useTheme } from '@mui/material'
import { object, string, TypeOf, number, date } from 'zod'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import {
  getFirstDayOfMonth,
  GraphqlError,
  numericString,
  OrderDirection,
  useCreatePayComponentHistoryMutation,
  useCreatePayComponentMutation,
  useGetActiveValueTypesPayComponentByPayrollCodeIdQuery,
  useGetAllPayrollCodesByEmployerIdQuery,
  useGetContractByIdQuery,
  useGetPayComponentHistoryByIdQuery,
  useUpdatePayComponentHistoryMutation,
  WithoutTime,
  addDaysToDate,
  FormSelectOption,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormActionButtons,
  FormDatepicker,
  FormErrorList,
  FormSelect,
  FormContainer,
  FormInput,
  FormGridLayout,
  useFlyIn
} from '@epix-web-apps/ui'
import { useEffect, useState } from 'react'

export const DATE_INPUT_FORMAT = 'MMMM yyyy'

/* eslint-disable-next-line */
export interface AddEditPaycomponentProps {
  contractId: string | undefined
  employerId: string | undefined | null
  startDate: Date
  endDate?: Date
  payComponentHistoryId?: string | undefined
  payComponentId?: string | undefined
  addRecord?: boolean
  editRecord?: boolean
  addRecordAfterClosedPayComponent?: boolean
  lastHistoryEndDate?: Date
}

export function AddEditPaycomponent({
  contractId,
  employerId,
  startDate,
  payComponentHistoryId,
  payComponentId,
  addRecord,
  editRecord,
  addRecordAfterClosedPayComponent,
  lastHistoryEndDate
}: AddEditPaycomponentProps) {
  const { t } = useTranslation()

  const addPaycomponentSchema = object({
    groupType: string({
      required_error: t('form.validation.grouptyperequired'),
      invalid_type_error: t('form.validation.grouptyperequired')
    }).min(1, t('form.validation.grouptyperequired')),
    subGroupType: string({
      required_error: t('form.validation.subgrouptyperequired'),
      invalid_type_error: t('form.validation.subgrouptyperequired')
    }).min(1, t('form.validation.subgrouptyperequired')),
    payrollCode: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    }).min(1, t('form.validation.payrollcoderequired')),
    value: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).positive({ message: t('form.validation.valuegreaterthen0') })
    ),
    valueType: string({
      required_error: t('form.validation.valuetyperequired'),
      invalid_type_error: t('form.validation.valuetyperequired')
    }).min(1, t('form.validation.valuetyperequired')),
    reasonSalaryChange: string().nullable().optional(),
    comment: string().nullable().optional(),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable()
  }).refine(data => (data.endDate ? WithoutTime(data.endDate) >= WithoutTime(data.startDate) : true), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })

  type CreatePaycomponentForm = TypeOf<typeof addPaycomponentSchema>

  const theme = useTheme()

  const { data: getPayrollcodes } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc,
    payrollCodeFilterModel: {
      showSalaryCodes: true
    }
  })

  const { data: getPayComponentById, refetch: refetchPayComponentById } = useGetPayComponentHistoryByIdQuery(
    {
      id: payComponentHistoryId ?? ''
    },
    {
      enabled: !!payComponentHistoryId
    }
  )

  const { data: getContractById } = useGetContractByIdQuery({
    contractId: contractId || ''
  })

  const currentHistory = getPayComponentById?.payComponentHistoryById

  const createMutation = useCreatePayComponentMutation()
  const createHistoryMutation = useCreatePayComponentHistoryMutation()
  const editMutation = useUpdatePayComponentHistoryMutation()

  const addNewPayComponent = !payComponentHistoryId
  const [isPaycomponentInContractPeriod, setIsPaycomponentInContractPeriod] = useState<boolean>(true)

  function calculateStartDate(): Date {
    if (addNewPayComponent) {
      return startDate
    } else if (editRecord) {
      return new Date(currentHistory?.startDate)
    } else if (addRecordAfterClosedPayComponent) {
      return addDaysToDate(new Date(lastHistoryEndDate!), 1)
    } else {
      return startDate
    }
  }

  function calculateEndDate(): Date | null {
    if (addNewPayComponent) {
      return startDate
    } else if (editRecord) {
      if (currentHistory?.endDate) {
        return new Date(currentHistory.endDate)
      } else return null
    } else if (addRecordAfterClosedPayComponent) {
      return addDaysToDate(new Date(lastHistoryEndDate!), 1)
    } else return null
  }

  function checkInBoundsForContractPeriod(startDate: Date, endDate?: Date | null) {
    let isValid = true
    const startDateBoundary = getFirstDayOfMonth(getContractById?.contractById.startDate)

    if (startDate < startDateBoundary) {
      isValid = false
    }

    if (getContractById?.contractById.endDate) {
      const endDateBoundary = getLastDayOfMonth(getContractById?.contractById.endDate)
      if (startDate > endDateBoundary) isValid = false

      if (endDate) {
        if (endDate > endDateBoundary) isValid = false
      }
    }

    setIsPaycomponentInContractPeriod(isValid)
  }

  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreatePaycomponentForm>({
    resolver: zodResolver(addPaycomponentSchema),
    defaultValues: {
      groupType: getPayComponentById?.payComponentHistoryById?.groupType.key,
      subGroupType: getPayComponentById?.payComponentHistoryById?.subGroupType.key,
      payrollCode: getPayComponentById?.payComponentHistoryById?.payrollCodeId,
      value: getPayComponentById?.payComponentHistoryById?.value,
      valueType: getPayComponentById?.payComponentHistoryById?.valueType.key,
      comment: getPayComponentById?.payComponentHistoryById?.comment || undefined,
      reasonSalaryChange: getPayComponentById?.payComponentHistoryById?.reasonSalaryChangeType?.key || undefined,
      startDate: calculateStartDate(),
      endDate: calculateEndDate()
    }
  })

  const [reason, setReason] = useState(false)
  const [comment, setComment] = useState(false)
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const { control } = form
  const watchedGroupTypeKey = useWatch({
    control,
    disabled: !!payComponentHistoryId,
    name: `groupType`
  })
  const watchedSubGroupTypeKey = useWatch({
    control,
    disabled: !!payComponentHistoryId,
    name: `subGroupType`
  })
  const watchedPayrollcodeId = useWatch({
    control,
    disabled: !!payComponentHistoryId,
    name: `payrollCode`
  })

  function getLastDayOfMonth(date: Date): Date {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return lastDay
  }

  const { data: allValueTypePayComponents } = useGetActiveValueTypesPayComponentByPayrollCodeIdQuery(
    {
      payrollCodeId: watchedPayrollcodeId || ''
    },
    {
      enabled: !!watchedPayrollcodeId,
      suspense: false,
      onSuccess: data => {
        form.resetField('valueType', {
          defaultValue: data.activeValueTypePayComponentByPayrollCodeId[0]?.key
        })
      }
    }
  )

  const { groupOptions, subGroupOptions, salaryChangedReasonOptions } = formTypeSelectOptions
  const allValueOptions =
    allValueTypePayComponents?.activeValueTypePayComponentByPayrollCodeId?.map(option => ({
      id: option.key,
      label: option.value,
      active: true
    })) ?? []

  useEffect(() => {
    setReason(getPayComponentById?.payComponentHistoryById?.reasonSalaryChangeType?.key !== undefined)
    setComment(getPayComponentById?.payComponentHistoryById?.comment !== undefined)
  }, [payComponentHistoryId])

  const handleOnSubmit = async (newPayComponent: CreatePaycomponentForm) => {
    if (payComponentHistoryId === undefined) {
      await createMutation
        .mutateAsync({
          createPayComponentCommand: {
            payrollCodeId: newPayComponent.payrollCode || '',
            contractId: contractId || '',
            employerId: employerId || '',
            startDate: newPayComponent.startDate,
            endDate: newPayComponent.endDate ? getLastDayOfMonth(newPayComponent.endDate) : null,
            value: newPayComponent.value,
            comment: newPayComponent.comment !== '' ? newPayComponent.comment : null,
            reasonSalaryChangeTypeKey: newPayComponent.reasonSalaryChange || null,
            valueTypeKey: newPayComponent.valueType
          }
        })
        .then(() => {
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      if (addRecord) {
        await createHistoryMutation
          .mutateAsync({
            createPayComponentHistoryCommand: {
              id: payComponentId ?? '',
              value: newPayComponent.value,
              comment: newPayComponent.comment !== '' ? newPayComponent.comment : null,
              reasonSalaryChangeTypeKey: newPayComponent.reasonSalaryChange ?? null,
              endDate: newPayComponent.endDate ? getLastDayOfMonth(newPayComponent.endDate) : null,
              startDate: newPayComponent.startDate
            }
          })
          .then(() => {
            refetchPayComponentById()
            closeFlyIn()
          })
          .catch(e => setBackendErrors([e]))
      } else {
        if (editRecord) {
          await editMutation
            .mutateAsync({
              updatePayComponentHistoryCommand: {
                id: payComponentId ?? '',
                value: newPayComponent.value,
                comment: newPayComponent.comment !== '' ? newPayComponent.comment : null,
                reasonSalaryChangeTypeKey: newPayComponent.reasonSalaryChange ?? null,
                endDate: newPayComponent.endDate ? getLastDayOfMonth(newPayComponent.endDate) : null,
                startDate: newPayComponent.startDate
              }
            })
            .then(() => {
              refetchPayComponentById()
              closeFlyIn()
            })
            .catch(e => setBackendErrors([e]))
        }
      }
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      {addRecord ? (
        <Typography variant="h4">{t('flyin.earning.addnewhistory')}</Typography>
      ) : editRecord ? (
        <Typography variant="h4">{t('flyin.earning.editcurrenthistory')}</Typography>
      ) : (
        <Typography variant="h4">{t('flyin.earning.additem')}</Typography>
      )}

      <FormGridLayout>
        <FormDatepicker
          sx={6}
          name="startDate"
          inputFormat={DATE_INPUT_FORMAT}
          disabled={editRecord || addRecordAfterClosedPayComponent}
          label={`${t('form.field.startdate')} *`}
          views={['year', 'month']}
          onChange={e => {
            checkInBoundsForContractPeriod(
              new Date(e),
              form.getValues().endDate ? new Date(form.getValues().endDate || '') : undefined
            )
          }}
        />

        <FormDatepicker
          sx={6}
          name="endDate"
          inputFormat={DATE_INPUT_FORMAT}
          disabled={
            (addRecord && !addRecordAfterClosedPayComponent) ||
            (editRecord && lastHistoryEndDate != getPayComponentById?.payComponentHistoryById.endDate)
          }
          label={`${t('form.field.enddate')}`}
          views={['year', 'month']}
          onChange={e => {
            checkInBoundsForContractPeriod(new Date(form.getValues().startDate || ''), new Date(e))
          }}
        />
        <FormSelect
          sx={12}
          name="groupType"
          disabled={!!payComponentHistoryId}
          label={`${t('form.field.grouptype')} *`}
          options={groupOptions}
        />

        <FormSelect
          sx={12}
          name="subGroupType"
          disabled={!!payComponentHistoryId}
          label={`${t('form.field.subgrouptype')} *`}
          options={subGroupOptions.filter(s => s.id.includes(watchedGroupTypeKey))}
        />

        <FormSelect
          sx={12}
          name="payrollCode"
          disabled={!!payComponentHistoryId}
          onChange={(e, payRollCode) => {
            const groupTypeKey = getPayrollcodes?.allPayrollCodesByEmployerId.data.find(x => x.id === payRollCode?.id)
              ?.group.key
            const subGroupTypeKey = getPayrollcodes?.allPayrollCodesByEmployerId.data.find(
              x => x.id === payRollCode?.id
            )?.subGroup?.key
            form.resetField('groupType', {
              defaultValue: groupTypeKey
            })
            form.resetField('subGroupType', {
              defaultValue: subGroupTypeKey
            })
          }}
          label={`${t('form.field.payrollcode')} *`}
          options={
            watchedGroupTypeKey || watchedSubGroupTypeKey
              ? getPayrollcodes?.allPayrollCodesByEmployerId.data
                  .filter(c => c.group.key.includes(watchedGroupTypeKey))
                  .filter(c => c.subGroup?.key.includes(watchedSubGroupTypeKey))
                  .map(x => new FormSelectOption(x.id, `${x.code} -  ${x.userFriendlyDescription ?? x.description}`))
              : getPayrollcodes?.allPayrollCodesByEmployerId.data.map(
                  x => new FormSelectOption(x.id, `${x.code} -  ${x.userFriendlyDescription ?? x.description}`)
                )
          }
        />

        <FormInput sx={6} name="value" placeholder={'0'} label={`${t('form.field.value')} *`} />

        <FormSelect sx={6} name="valueType" label={`${t('form.field.valuetype')} *`} options={allValueOptions} />

        <Grid xs={6} item>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setReason(!reason)
            }}
          >
            {t('flyin.addpaycomponet.addreason')}
          </Button>
        </Grid>
        <Grid xs={6} item>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setComment(!comment)
            }}
          >
            {t('flyin.addpaycomponet.addcomment')}
          </Button>
        </Grid>

        {reason && (
          <FormSelect
            sx={12}
            name="reasonSalaryChange"
            label={`${t('form.field.reasonsalarychange')}`}
            options={salaryChangedReasonOptions}
          />
        )}

        {comment && <FormInput sx={12} name="comment" multiline={true} label={t('form.field.comment')} />}
      </FormGridLayout>

      {!isPaycomponentInContractPeriod && (
        <Typography variant="h6" sx={{ color: theme.palette.warning.light }}>
          {t('flyin.addpaycomponet.paycomponentdoesnotmatchperiod')}
        </Typography>
      )}

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isLoading || editMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditPaycomponent
