import { EndSessionRequest, RedirectRequest } from "@azure/msal-browser"
import { environment } from "./environments/environment"

const HOSTARRAY = window.location.host.split('.')
export const SUBDOMAIN_STATE_PREFIX = 'subdomain-'
export const AUTHORITY_BASE = `https://${environment.auth.b2cDomainName}/${environment.auth.tenantName}/`
export const PORT = window.location.port ? `:${window.location.port}` : ''
export const SUBDOMAIN = HOSTARRAY.length > 1 ? HOSTARRAY[0] : ''
export const loginRequest: RedirectRequest = {
  scopes: ['https://myepix.onmicrosoft.com/525b221b-2a03-4113-a313-badcc1726282/People.ReadWrite.All'],
  state: `${SUBDOMAIN_STATE_PREFIX}${SUBDOMAIN}`,
  domainHint: SUBDOMAIN
}
export const logoutRequest: EndSessionRequest = {
  state: `${SUBDOMAIN_STATE_PREFIX}${SUBDOMAIN}`
}
export const resetPasswordRequest = {
  ...loginRequest,
  authority: 'https://myepix.b2clogin.com/myepix.onmicrosoft.com/b2c_1_reset_password'
}
