import { DEFAULT_FLYIN_WIDTH, useGetProcessByIdQuery, useUpdateProcessMutation } from '@epix-web-apps/core'
import { Box, Switch, Typography, useTheme } from '@mui/material'
import { GridRowId } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

export interface CancelProcessFlyinProps {
  processId: GridRowId | undefined
}

function CancelProcessFlyin({ processId }: CancelProcessFlyinProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { data: getProcess, refetch: refetchProcess } = useGetProcessByIdQuery({
    id: processId?.toString() || ''
  })

  const updateProcessMutation = useUpdateProcessMutation()

  function handleCancelProcess(cancelled: boolean) {
    updateProcessMutation
      .mutateAsync({
        updateProcessCommand: {
          processId: processId?.toString() || '',
          payrollProviderId: getProcess?.processById.payrollProviderId || '',
          dueDate: getProcess?.processById.dueDate || undefined,
          employerId: getProcess?.processById.employerId || '',
          payGroupId: getProcess?.processById.payGroupId || '',
          name: getProcess?.processById.name || '',
          cancelled: cancelled
        }
      })
      .then(() => {
        refetchProcess()
      })
  }

  return (
    <Box sx={{ width: DEFAULT_FLYIN_WIDTH, display: 'flex', flexDirection: 'column' }}>
      <Typography color={theme.palette.primary.main} variant="h4" noWrap sx={{ mt: 3 }}>
        {getProcess?.processById.name}
      </Typography>

      {!getProcess?.processById.completed ? (
        <p>
          <Switch
            defaultChecked={getProcess?.processById.cancelled ?? false}
            onChange={(e, checked) => handleCancelProcess(checked)}
            aria-label={t('processpage.cancelprocess')}
          />
          {t('processpage.cancelprocess')}
        </p>
      ) : (
        <p>{t('processpage.cantcancelprocess')}</p>
      )}
    </Box>
  )
}

export default CancelProcessFlyin
