import { FilterSelectBox, useFlyIn } from '@epix-web-apps/ui'
import { Box, Button, Switch, TextField, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_FLYIN_WIDTH, formTypeSelectOptions } from '@epix-web-apps/core'

export type PayrollCodeFilters = {
  code: string
  description: string
  group: string | null
  subGroup: string | null
  showSalaryCodes: boolean
  showCalendarCodes: boolean
  usableInSelfService: boolean
}

/* eslint-disable-next-line */
export interface FilterPayrollCodesFlyinProps {
  filters: PayrollCodeFilters
  updateStore: (filters: PayrollCodeFilters) => void
}

export function FilterPayrollCodesFlyin({ filters: initialFilters, updateStore }: FilterPayrollCodesFlyinProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const [filters, setFilters] = useState(initialFilters)

  function handleFilter() {
    updateStore({ ...filters })
    closeFlyIn()
  }

  function handleReset() {
    updateStore({
      code: '',
      description: '',
      group: null,
      subGroup: null,
      showSalaryCodes: false,
      showCalendarCodes: false,
      usableInSelfService: false
    })
    closeFlyIn()
  }

  const { groupOptions, subGroupOptions } = formTypeSelectOptions
  const filteredSubGroupOptions = subGroupOptions.filter(sgo => (filters.group ? sgo.id.includes(filters.group) : null))

  return (
    <Box sx={{ width: DEFAULT_FLYIN_WIDTH }}>
      <Box>
        <Typography color={theme.palette.primary.main} variant="h4">
          {t('payrollcodespage.filters.title')}
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '1rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <TextField
            label={t('payrollcodespage.filters.payrollcode')}
            size="small"
            fullWidth
            value={filters.code ?? ''}
            onChange={e => setFilters({ ...filters, code: e.currentTarget.value })}
          />
          <TextField
            label={t('payrollcodespage.filters.userfriendlydescription')}
            size="small"
            fullWidth
            value={filters.description ?? ''}
            onChange={e => setFilters({ ...filters, description: e.currentTarget.value })}
          />
        </Box>
        <Box sx={{ py: '1rem' }}>
          <FilterSelectBox
            id="groupId"
            label={t('payrollcodespage.filters.group')}
            keys={groupOptions}
            value={filters.group}
            handleChange={(e: string) => setFilters({ ...filters, group: e ?? null, subGroup: null })}
          />
          <FilterSelectBox
            id="subGroupId"
            label={t('payrollcodespage.filters.subgroup')}
            keys={filteredSubGroupOptions}
            value={filters.subGroup}
            handleChange={(e: string) => setFilters({ ...filters, subGroup: e ?? null })}
          />
          <p>
            <Switch
              checked={filters.showSalaryCodes}
              onChange={e =>
                setFilters({
                  ...filters,
                  showSalaryCodes: e.currentTarget.checked
                })
              }
            />
            {t('payrollcodespage.filters.showsalarycodes')}
          </p>
          <p>
            <Switch
              checked={filters.showCalendarCodes}
              onChange={e =>
                setFilters({
                  ...filters,
                  showCalendarCodes: e.currentTarget.checked
                })
              }
            />
            {t('payrollcodespage.filters.showcalendarcodes')}
          </p>
          <p>
            <Switch
              checked={filters.usableInSelfService}
              onChange={e =>
                setFilters({
                  ...filters,
                  usableInSelfService: e.currentTarget.checked
                })
              }
            />
            {t('payrollcodespage.filters.availableinselfservice')}
          </p>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button variant="contained" onClick={handleFilter}>
            {t('payrollcodespage.filters.button.filter')}
          </Button>
          <Button variant="outlined" onClick={closeFlyIn}>
            {t('payrollcodespage.filters.button.cancel')}
          </Button>
        </Box>

        <Button variant="text" onClick={handleReset}>
          {t('payrollcodespage.filters.button.reset')}
        </Button>
      </Box>
    </Box>
  )
}

export default FilterPayrollCodesFlyin
