import EditIcon from '@mui/icons-material/Edit'
import { useGetAsbGroupContractDetailsQuery } from '@epix-web-apps/core'
import { DetailBox, DetailBoxRow, useFlyIn } from '@epix-web-apps/ui'
import { Box, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import EditAsbgroupSocialSecurity from './edit-asbgroup-social-security/edit-asbgroup-social-security'
import EditAsbgroupTaxes from './edit-asbgroup-taxes/edit-asbgroup-taxes'
import EditAsbgroupContractInfo from './edit-asbgroup-contract-info'
import EditAsbgroupTrexima from './edit-asbgroup-trexima'

/* eslint-disable-next-line */
export interface CzAsbgroupProps {
  contractId: string
}

export function CzAsbgroup({ contractId }: CzAsbgroupProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails } = useGetAsbGroupContractDetailsQuery({
    contractId: contractId || ''
  })

  const socialSecurity = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupSocialSecurityModel
  const contractInfo = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupContractInfoModel
  const taxes = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupTaxesModel
  const trexima = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupTreximaModel

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('asbgroupdetailpage.socialsecurity.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsbgroupSocialSecurity contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {socialSecurity?.healthInsuranceCompanyType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.socialsecurity.healthinsurancecompany')}:
                <span className="bold"> {socialSecurity.healthInsuranceCompanyType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>
        <DetailBox
          title={t('asbgroupdetailpage.contractinfo.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsbgroupContractInfo contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {contractInfo?.ppContractType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.contractinfo.ppcontracttype')}:
                <span className="bold"> {contractInfo?.ppContractType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {contractInfo?.socialSecurityContractType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.contractinfo.socialsecuritycontracttype')}:
                <span className="bold"> {contractInfo.socialSecurityContractType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {contractInfo?.alteredWorkingAbilityType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.contractinfo.alteredworkingability')}:
                <span className="bold"> {contractInfo.alteredWorkingAbilityType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {contractInfo?.disabilityType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.contractinfo.disability')}:
                <span className="bold"> {contractInfo?.disabilityType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>

      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('asbgroupdetailpage.taxes.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsbgroupTaxes contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {taxes?.hasSignedTaxReliefForm ? (
            <>
              <DetailBoxRow>
                <p>{t('asbgroupdetailpage.taxes.signedtaxreliefformactive')}</p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {taxes?.hasEmployeeTaxReduction
                    ? t('asbgroupdetailpage.taxes.employeetaxreductionactive')
                    : t('asbgroupdetailpage.taxes.employeetaxreductioninactive')}
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {taxes?.hasDisabilityReduction
                    ? t('asbgroupdetailpage.taxes.disabilityreductionactive')
                    : t('asbgroupdetailpage.taxes.disabilityreductioninactive')}
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {taxes?.willApplyTaxRelief
                    ? t('asbgroupdetailpage.taxes.taxreliefactive')
                    : t('asbgroupdetailpage.taxes.taxreliefinactive')}
                </p>
              </DetailBoxRow>
            </>
          ) : (
            <DetailBoxRow>
              <p>{t('asbgroupdetailpage.taxes.signedtaxreliefforminactive')}</p>
            </DetailBoxRow>
          )}
        </DetailBox>
        <DetailBox
          title={t('asbgroupdetailpage.trexima.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsbgroupTrexima contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {trexima?.isSubjectToTreximaReporting ? (
            <>
              <DetailBoxRow>
                <p>{t('asbgroupdetailpage.trexima.subjectedtotreximareporting')}</p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {t('asbgroupdetailpage.trexima.highesteducation')}:
                  <span className="bold"> {trexima?.educationType?.value}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {t('asbgroupdetailpage.trexima.highesteducationcode')}:
                  <span className="bold"> {trexima?.educationCode}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {t('asbgroupdetailpage.trexima.workpositioncode')}:
                  <span className="bold"> {trexima?.workPositionCode}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {t('asbgroupdetailpage.trexima.contractclassification')}:
                  <span className="bold"> {trexima?.contractClassificationType?.value}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {trexima?.isManagementLevel
                    ? t('asbgroupdetailpage.trexima.onmanagementlevel')
                    : t('asbgroupdetailpage.trexima.notonmanagementlevel')}
                </p>
              </DetailBoxRow>
            </>
          ) : (
            <DetailBoxRow>
              <p>{t('asbgroupdetailpage.trexima.notsubjectedtotreximareporting')}</p>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>
    </Box>
  )
}

export default CzAsbgroup
