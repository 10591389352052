import { Button, Box, IconButton, MenuItem, useTheme, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { GridColDef } from '@mui/x-data-grid'
import { EpixAvatar, People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { AddPerson } from '../../components/people-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  isAuthorized,
  useNavigateWithParams,
  OrderDirection,
  ROLE,
  useGetAllIcpsQuery,
  useGetMeQuery,
  useGetPagedPeopleQuery,
  formTypeSelectOptions,
  PeopleFilterSearchProperty
} from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { DataTable, SortModel } from '../../components/data-table'
import { useSearchParams } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import DropdownMenu from '../../components/dropdown-menu'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { IcpCodeBadge } from '../../components/icp-components'
import { usePeopleFilterStore } from '../../stores/people-page-filter-store'
import SettingsButton from '../../components/settings-button'
import OnboardingPersonLayout from '../../components/onboarding-components/onboarding-person-layout'
import RemovePersonFlyin from '../../components/people-components/remove-person/remove-person-flyin'
import { FilterButtonFlyIn } from '../../components/filter-button'
import { FilterPeopleFlyIn } from '../../components/people-components/filter-people-flyin'

export interface PeopleFilters {
  searchProperty: PeopleFilterSearchProperty
  searchString: string | null
  employer: string | null
  paygroup: string | null
  showInactive: boolean
  showWithoutContract: boolean
  showValidations: boolean
}

type TableRow = {
  id: string
  firstName: string
  lastName: string
  personNumber: string
  startedOn: string
  countryKeys: string[]
  validations: { errorCount: number; warningCount: number }
}

/* eslint-disable-next-line */
export interface PeoplePageProps {}

export function PeoplePage(props: PeoplePageProps) {
  const { data: me } = useGetMeQuery()
  const navigateWithParams = useNavigateWithParams()
  const { t } = useTranslation()
  const { openFlyIn, closeFlyIn } = useFlyIn()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') ?? '0')
  const [pageSize, setPageSize] = useState(25)
  const [totalRows, setTotalRows] = useState(0)
  const [clickedPersonId, setClickedPersonId] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [tableData, setTableData] = useState<TableRow[]>([])

  const store = usePeopleFilterStore()

  const filters: PeopleFilters = {
    searchProperty: store.searchProperty,
    searchString: store.searchString,
    employer: store.employer,
    paygroup: store.paygroup,
    showInactive: store.showInactive,
    showWithoutContract: store.showWithoutContract,
    showValidations: store.showValidations
  }

  function updateStore(values: PeopleFilters) {
    store.setSearchProperty(values.searchProperty)
    store.setSearchString(values.searchString)
    store.setEmloyer(values.employer)
    store.setPaygroup(values.paygroup)
    store.setShowInactive(values.showInactive)
    store.setShowWithoutContract(values.showWithoutContract)
    store.setShowValidations(values.showValidations)
    searchParams.set('page', '0')
    setSearchParams(searchParams)
  }

  const isAnyFilterActive =
    (filters.searchString !== null && filters.searchString !== '') ||
    filters.employer !== null ||
    filters.paygroup !== null ||
    filters.showInactive ||
    filters.showWithoutContract ||
    filters.showValidations

  const { icpFilters } = useGlobalPersistedStore()
  const { data, isLoading, refetch } = useGetPagedPeopleQuery(
    {
      icpIds: icpFilters,
      searchProperty: store.searchProperty,
      searchString: store.searchString,
      employerId: store.employer,
      paygroupId: store.paygroup,
      showInactive: store.showInactive,
      showWithoutContract: store.showWithoutContract,
      showValidations: store.showValidations,
      sortByProperty: store.sortByProperty,
      orderDirection: store.sortDirection,
      offset: page * pageSize,
      limit: pageSize
    },
    { suspense: false }
  )

  const { employerOptions } = formTypeSelectOptions
  const { data: icps } = useGetAllIcpsQuery({
    offset: 0,
    limit: 0,
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (data) {
      const tableData = data.pagedPeople.data.map(row => ({
        id: row.personId,
        firstName: row.firstName,
        lastName: row.lastName,
        personNumber: row.personNumber ?? '',
        startedOn: row.startedOn ? new Date(row.startedOn).toLocaleDateString() : '',
        countryKeys: row.countryKeys,
        validations: {
          errorCount: row.validationErrorCount,
          warningCount: row.validationWarningCount
        }
      }))
      setTableData(tableData)
      setTotalRows(data.pagedPeople.totalCount)
    }
  }, [data])

  useEffect(() => {
    store.setSortByProperty(columns.find(c => c.field === 'lastName')?.field ?? '')
    store.setSortDirection(OrderDirection.Asc)
  }, [])

  const rowActions = useCallback((row: TableRow) => {
    return (
      <>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            if (row.id) {
              setClickedPersonId(row.id)
            }
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton aria-label="row details" aria-controls="details-row">
          <KeyboardArrowRightIcon />
        </IconButton>
      </>
    )
  }, [])

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'avatar',
        headerName: '',
        width: 50,
        renderCell: rowParams => RenderAvatar(rowParams.row)
      },
      {
        field: 'firstName',
        headerName: t('peoplepage.datatable.column.firstname'),
        flex: 1
      },
      {
        field: 'lastName',
        headerName: t('peoplepage.datatable.column.lastname'),
        flex: 1
      },
      {
        field: 'personNumber',
        headerName: t('peoplepage.datatable.column.personnumber'),
        flex: 1
      },
      {
        field: 'startedOn',
        headerName: t('peoplepage.datatable.column.startedon'),
        flex: 1
      },
      {
        field: 'contract',
        headerName: t('peoplepage.datatable.column.contracts'),
        sortable: false,
        renderCell: rowParams => ContractRowData(rowParams.row)
      },
      {
        field: 'validations',
        sortable: false,
        headerName: t('peoplepage.datatable.column.validations'),
        renderCell: rowParams => ValidationRowData(rowParams.row)
      },
      {
        field: '',
        sortable: false,
        editable: false,
        renderCell: rowParams => rowActions(rowParams.row)
      }
    ],
    []
  )

  function goToPersonDetailPage(id: string) {
    navigateWithParams(People.PEOPLE_ID_DETAILS(id))
  }

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Typography m={0} variant="h2">
        {t('peoplepage.title')}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FilterButtonFlyIn
            filled={isAnyFilterActive}
            flyinContent={<FilterPeopleFlyIn filters={store} updateFilters={updateStore} />}
          />
          {isAuthorized(me?.me.roles, [ROLE.MANAGELISTS, ROLE.ADMIN]) && (
            <SettingsButton
              menuItems={[
                {
                  label: t('peoplepage.settings.functionareas'),
                  to: '/configuration/function-areas'
                },
                {
                  label: t('peoplepage.settings.functionlevels'),
                  to: '/configuration/function-levels'
                }
              ]}
            />
          )}
        </Box>

        {isAuthorized(me?.me.roles, [ROLE.CREATEPERSON, ROLE.ADMIN]) && (
          <Button
            variant="contained"
            disabled={(icps?.icps && icps?.icps.totalCount === 0) || (employerOptions && employerOptions.length === 0)}
            onClick={() =>
              openFlyIn({
                content: <AddPerson />,
                callbackAfterClose: () => refetch
              })
            }
          >
            <AddIcon />
            {t('peoplepage.button.addperson')}
          </Button>
        )}
      </Box>
    </Box>
  )

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={totalRows}
        onSortChange={sortModel => {
          store.setSortByProperty(sortModel.field)
          store.setSortDirection(sortModel.orderDirection)
        }}
        page={page}
        onPageChange={v => {
          searchParams.set('page', `${v}`)
          setSearchParams(searchParams)
        }}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onRowClick={params => params.row.id && goToPersonDetailPage(params.row.id)}
        isLoading={isLoading}
        defaultSortModel={new SortModel(store.sortByProperty, store.sortDirection)}
        filterBarElement={filterBar}
        emptyStateElement={
          <OnboardingPersonLayout
            onClick={() =>
              openFlyIn({
                content: <AddPerson />,
                callbackAfterClose: () => refetch
              })
            }
            icpsPresent={(icps?.icps && icps.icps.totalCount > 0) ?? false}
            employersPresent={(employerOptions && employerOptions.length > 0) ?? false}
          />
        }
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => clickedPersonId && goToPersonDetailPage(clickedPersonId)}>
          {t('peoplepage.list.row.menu.viewdetails')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemovePersonFlyin personId={clickedPersonId ?? ''} closeFlyIn={closeFlyIn} />
            })
          }
        >
          {t('common.delete')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default PeoplePage

function RenderAvatar(row: TableRow) {
  return <EpixAvatar personId={row.id} />
}

function ContractRowData(row: TableRow) {
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {row.countryKeys.map((icpCode: string, index: number) => (
        <IcpCodeBadge key={index} icpCode={icpCode} />
      ))}
    </Box>
  )
}

function ValidationRowData(row: TableRow) {
  const theme = useTheme()
  const errors = row.validations.errorCount
  const warnings = row.validations.warningCount

  if (errors === 0 && warnings === 0) {
    return (
      <Typography color={theme.palette.success.main}>
        <CheckIcon />
      </Typography>
    )
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {warnings > 0 && (
          <Typography color={theme.palette.warning.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WarningAmberOutlinedIcon sx={{ fontSize: 18 }} />
            {warnings}
          </Typography>
        )}
        {errors > 0 && (
          <Typography color={theme.palette.error.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ErrorOutlineOutlinedIcon sx={{ fontSize: 18 }} />
            {errors}
          </Typography>
        )}
      </Box>
    )
  }
}
