import {
  OrderDirection,
  useGetAllPayComponentsQuery,
  useGetContractByIdQuery,
  useGetMeQuery,
  CALCULATED_DATA_VALUE_TYPE_KEYS,
  DateRange,
  VALUE_TYPE_KEYS
} from '@epix-web-apps/core'
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../data-table'
import { Box, Typography, Button, useTheme, IconButton, MenuItem, Paper } from '@mui/material'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { PeriodNavigationDatePicker } from '../period-navigation-date-picker'
import { useFlyIn } from '@epix-web-apps/ui'
import { AddEditPaycomponent } from '../add-edit-paycomponent'
import { RemovePaycomponent } from '../remove-paycomponent'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DropdownMenu from '../../dropdown-menu'
import DataTableEmpty from '../../data-table/data-table-empty'
import { ViewPaycomponentHistory } from '../view-paycomponent-history'

/* eslint-disable-next-line */
interface ContractsSalaryTabProps {}

export function ContractsSalaryTab(props: ContractsSalaryTabProps) {
  const params = useParams<ContractDetailPageParams>()
  const { data: getContractById } = useGetContractByIdQuery(
    {
      contractId: params?.contractid || ''
    },
    {
      enabled: !!params?.contractid
    }
  )
  const contract = getContractById?.contractById

  const initialPeriod = useMemo(() => {
    const date = new Date()
    let numberOfDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    const numberOfDaysCurrentPayPeriod = new Date(
      new Date(contract?.currentPayPeriod).getFullYear(),
      new Date(contract?.currentPayPeriod).getMonth() + 1,
      0
    ).getDate()
    let startDate = new Date(date.getFullYear(), date.getMonth(), 1)
    const startDateCurrentPayPeriod = new Date(
      new Date(contract?.currentPayPeriod).getFullYear(),
      new Date(contract?.currentPayPeriod).getMonth(),
      1
    )
    let endDate = new Date(date.getFullYear(), date.getMonth(), numberOfDays)
    const endDateCurrentPayPeriod = new Date(
      new Date(contract?.currentPayPeriod).getFullYear(),
      new Date(contract?.currentPayPeriod).getMonth(),
      numberOfDaysCurrentPayPeriod
    )

    if (contract?.currentPayPeriod !== null) {
      startDate = new Date(startDateCurrentPayPeriod.getFullYear(), startDateCurrentPayPeriod.getMonth(), 1)
      endDate = new Date(
        endDateCurrentPayPeriod.getFullYear(),
        endDateCurrentPayPeriod.getMonth(),
        numberOfDaysCurrentPayPeriod
      )
    } else {
      if (
        (contract?.endDate === null && new Date(contract?.startDate) <= date) ||
        (new Date(contract?.endDate) <= endDate && contract?.endDate >= startDate)
      ) {
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
        endDate = new Date(endDate.getFullYear(), endDate.getMonth(), numberOfDays)
      } else if (new Date(contract?.startDate) >= date) {
        startDate = new Date(new Date(contract?.startDate).getFullYear(), new Date(contract?.startDate).getMonth(), 1)
        numberOfDays = new Date(
          new Date(contract?.startDate).getFullYear(),
          new Date(contract?.startDate).getMonth() + 1,
          0
        ).getDate()
        endDate = new Date(
          new Date(contract?.startDate).getFullYear(),
          new Date(contract?.startDate).getMonth(),
          numberOfDays
        )
      } else {
        startDate = new Date(new Date(contract?.endDate).getFullYear(), new Date(contract?.endDate).getMonth(), 1)
        numberOfDays = new Date(
          new Date(contract?.endDate).getFullYear(),
          new Date(contract?.endDate).getMonth() + 1,
          0
        ).getDate()
        endDate = new Date(
          new Date(contract?.endDate).getFullYear(),
          new Date(contract?.endDate).getMonth(),
          numberOfDays
        )
      }
    }
    return {
      startDate: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
      endDate: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
    } as DateRange
  }, [contract])

  const [period, setPeriod] = useState<DateRange>(initialPeriod)
  const { t } = useTranslation()
  const theme = useTheme()
  const { data: me } = useGetMeQuery()
  const { openFlyIn } = useFlyIn()
  const [tableDataEarnings, setTableDataEarnings] = useState<GridRowsProp>([])
  const [tableDataDeductions, setTableDataDeductions] = useState<GridRowsProp>([])

  const {
    data: earnings,
    isLoading: isLoadingEarnings,
    refetch: refetchEarnings
  } = useGetAllPayComponentsQuery(
    {
      contractId: params.contractid || '',
      startDate: period?.startDate,
      endDate: period?.endDate,
      isDeduction: false
    },
    { suspense: false }
  )
  const {
    data: deductions,
    isLoading: isLoadingDeductions,
    refetch: refetchDeductions
  } = useGetAllPayComponentsQuery(
    {
      contractId: params.contractid || '',
      startDate: period?.startDate,
      endDate: period?.endDate,
      isDeduction: true
    },
    { suspense: false }
  )

  useEffect(() => {
    refetchEarnings()
    refetchDeductions()
  }, [me?.me.currency])

  useEffect(() => {
    if (earnings && earnings?.payComponents) {
      const payComponentsData = earnings.payComponents
      setTableDataEarnings(
        payComponentsData.map(row => {
          return {
            id: row.payComponentHistory.current?.id,
            payComponentId: row.payComponentId,
            payrollCode: row.payComponentHistory.current?.payrollCode,
            description: row.payComponentHistory.current?.description,
            value: row.payComponentHistory.current?.value,
            valueType: row.payComponentHistory.current?.valueType.key,
            valueRate: row.payComponentHistory.current?.valueRate,
            startDate: new Date(row.payComponentHistory.current?.startDate).toLocaleDateString(),
            endDate: row.payComponentHistory.current?.endDate
              ? new Date(row.payComponentHistory.current?.endDate).toLocaleDateString()
              : null
          }
        })
      )
      if (earnings?.payComponents.length > 0) {
        if (earnings?.payComponents[0].icpCurrency === me?.me.currency) {
          setColumnVisibilityModel({ valueRate: false, validFrom: false })
          setCurrencyRateHeader('')
        } else {
          setColumnVisibilityModel({ valueRate: true, validFrom: false })
          setCurrencyRateHeader(me?.me.currencyName)
        }
      }
    }
    if (deductions && deductions?.payComponents) {
      const payComponentsData = deductions.payComponents
      setTableDataDeductions(
        payComponentsData.map(row => {
          return {
            id: row.payComponentHistory.current?.id,
            payComponentId: row.payComponentId,
            payrollCode: row.payComponentHistory.current?.payrollCode,
            description: row.payComponentHistory.current?.description,
            value: row.payComponentHistory.current?.value,
            valueType: row.payComponentHistory.current?.valueType.key,
            valueRate: row.payComponentHistory.current?.valueRate,
            startDate: new Date(row.payComponentHistory.current?.startDate).toLocaleDateString(),
            endDate: row.payComponentHistory.current?.endDate
              ? new Date(row.payComponentHistory.current?.endDate).toLocaleDateString()
              : null
          }
        })
      )
    }
  }, [deductions, earnings, me?.me])

  const [clickedPayComponentHistoryId, setClickedPayComponentHistoryId] = useState<string>()
  const [clickedPayComponentId, setClickedPayComponentId] = useState<string>()
  const [currencyRateHeader, setCurrencyRateHeader] = useState<string>()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const valueActions = (rowParams: GridRenderCellParams) =>
    rowParams.row.valueType === CALCULATED_DATA_VALUE_TYPE_KEYS.PERCENTAGE ? (
      <p>
        {rowParams.row.value} {'%'}
      </p>
    ) : rowParams.row.valueType === VALUE_TYPE_KEYS.HOURS ? (
      <p>
        {rowParams.row.value} {t('common.hours')}
      </p>
    ) : rowParams.row.valueType === VALUE_TYPE_KEYS.DEFAULT ? (
      <p>
        {rowParams.row.value} {earnings?.payComponents[0]?.icpCurrencySymbol}
      </p>
    ) : (
      <p>{rowParams.row.value}</p>
    )

  const valueRateActions = (rowParams: GridRenderCellParams) =>
    rowParams.row.valueType === VALUE_TYPE_KEYS.DEFAULT ? (
      <p>
        {rowParams.row.valueRate} {me?.me.currencySymbol}
      </p>
    ) : (
      <p></p>
    )

  const periodActions = (rowParams: GridRenderCellParams) => (
    <p>
      {rowParams.row.startDate} - {rowParams.row.endDate ? rowParams.row.endDate : '...'}
    </p>
  )

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedPayComponentHistoryId(rowParams.row.id)
          setClickedPayComponentId(rowParams.row.payComponentId)
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    </>
  )

  const columns: GridColDef[] = [
    {
      field: 'payrollCode',
      headerName: t('contractsalarypage.datatable.column.payrollcode'),
      flex: 0,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'description',
      headerName: t('contractsalarypage.datatable.column.description'),
      flex: 2,
      sortable: false
    },
    {
      field: 'value',
      headerName: t('contractsalarypage.datatable.column.value'),
      flex: 1,
      renderCell: valueActions,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'valueRate',
      headerName: currencyRateHeader,
      flex: 1,
      sortable: false,
      renderCell: valueRateActions,
      hideable: true,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'startDate',
      headerName: t('contractsalarypage.datatable.column.period'),
      flex: 2,
      renderCell: periodActions,
      sortable: false
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    valueRate: true,
    validFrom: false
  })

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))

  const filterBar = (isDeduction: boolean) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      {!isDeduction ? (
        <Typography
          sx={{
            textAlign: 'left',
            width: '40%',
            color: theme.palette.success.dark
          }}
          variant="h4"
        >
          {t('contractsalarypage.earnings')}
        </Typography>
      ) : (
        <Typography
          sx={{
            textAlign: 'left',
            width: '40%',
            color: theme.palette.error.dark
          }}
          variant="h4"
        >
          {t('contractsalarypage.deductions')}
        </Typography>
      )}
      {!isDeduction && (
        <PeriodNavigationDatePicker startDate={period.startDate} onChange={(period: DateRange) => setPeriod(period)} />
      )}
      {!isDeduction && (
        <Box width={'40%'} textAlign="right">
          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: (
                  <AddEditPaycomponent
                    contractId={contract?.id}
                    employerId={contract?.employerId}
                    startDate={period?.startDate}
                  />
                ),
                callbackAfterClose: () => () => {
                  refetchDeductions()
                  refetchEarnings()
                }
              })
            }
          >
            <AddIcon />
            {t('salarypage.button.additem')}
          </Button>
        </Box>
      )}
    </Box>
  )

  function isCommentNullInAllObjects(objects: any[] | undefined): boolean {
    return !objects?.some((item: any) => item.payComponentHistory.current?.comment !== null)
  }

  function combineObjectArrays(): any[] {
    if (earnings?.payComponents !== undefined && deductions?.payComponents) {
      return earnings?.payComponents.concat(deductions?.payComponents)
    }
    return []
  }

  return (
    <>
      <DataTable
        autoHeight
        data={tableDataEarnings}
        columns={columns}
        onSortChange={setSortModel}
        isLoading={isLoadingEarnings}
        defaultSortModel={sortModel}
        filterBarElement={filterBar(false)}
        hideFooter={true}
        columnsToHide={columnVisibilityModel}
        emptyStateElement={<DataTableEmpty title={t('emptystate.paycomponent')} />}
      />
      <DataTable
        autoHeight
        data={tableDataDeductions}
        columns={columns}
        onSortChange={setSortModel}
        isLoading={isLoadingDeductions}
        defaultSortModel={sortModel}
        filterBarElement={filterBar(true)}
        hideFooter={true}
        columnsToHide={columnVisibilityModel}
        emptyStateElement={<DataTableEmpty title={t('emptystate.paycomponent')} />}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <AddEditPaycomponent
                  contractId={contract?.id}
                  employerId={contract?.employerId}
                  startDate={period.startDate}
                  payComponentId={clickedPayComponentId}
                  payComponentHistoryId={clickedPayComponentHistoryId}
                  editRecord={true}
                  lastHistoryEndDate={
                    earnings?.payComponents.find(p => p.payComponentId === clickedPayComponentId)?.lastHistoryEndDate ||
                    deductions?.payComponents.find(p => p.payComponentId === clickedPayComponentId)?.lastHistoryEndDate
                  }
                />
              ),
              callbackAfterClose: () => () => {
                refetchDeductions()
                refetchEarnings()
              }
            })
          }
        >
          {t('contractsalarypage.list.row.menu.editpaycomponent')}
        </MenuItem>

        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <AddEditPaycomponent
                  contractId={contract?.id}
                  employerId={contract?.employerId}
                  startDate={period.startDate}
                  payComponentId={clickedPayComponentId}
                  payComponentHistoryId={clickedPayComponentHistoryId}
                  addRecord={true}
                />
              ),
              callbackAfterClose: () => () => {
                refetchDeductions()
                refetchEarnings()
              }
            })
          }
        >
          {t('contractsalarypage.list.row.menu.addpaycomponent')}
        </MenuItem>

        {(earnings?.payComponents.find(p => p.payComponentId === clickedPayComponentId)?.lastHistoryEndDate ||
          deductions?.payComponents.find(p => p.payComponentId === clickedPayComponentId)?.lastHistoryEndDate) && (
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: (
                  <AddEditPaycomponent
                    contractId={contract?.id}
                    employerId={contract?.employerId}
                    startDate={period.startDate}
                    payComponentId={clickedPayComponentId}
                    payComponentHistoryId={clickedPayComponentHistoryId}
                    addRecord={true}
                    addRecordAfterClosedPayComponent={true}
                    lastHistoryEndDate={
                      earnings?.payComponents.find(p => p.payComponentId === clickedPayComponentId)
                        ?.lastHistoryEndDate ||
                      deductions?.payComponents.find(p => p.payComponentId === clickedPayComponentId)
                        ?.lastHistoryEndDate
                    }
                  />
                ),
                callbackAfterClose: () => () => {
                  refetchDeductions()
                  refetchEarnings()
                }
              })
            }
          >
            {t('contractsalarypage.list.row.menu.addpaycomponentafterclosedpaycomponent')}
          </MenuItem>
        )}

        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <RemovePaycomponent
                  payComponentId={clickedPayComponentId || ''}
                  payComponentHistoryId={clickedPayComponentHistoryId || ''}
                  startDate={period.startDate}
                />
              ),
              callbackAfterClose: () => () => {
                refetchDeductions()
                refetchEarnings()
              }
            })
          }
        >
          {t('contractsalarypage.list.row.menu.removepaycomponent')}
        </MenuItem>

        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <ViewPaycomponentHistory
                  payComponentId={clickedPayComponentId || ''}
                  contractId={contract?.id || ''}
                  endDate={period.endDate || new Date()}
                  icpCurrencySymbol={
                    earnings?.payComponents[0].icpCurrencySymbol || deductions?.payComponents[0].icpCurrencySymbol || ''
                  }
                />
              ),
              callbackAfterClose: () => () => {
                refetchDeductions()
                refetchEarnings()
              }
            })
          }
        >
          {t('contractsalarypage.list.row.menu.viewhistory')}
        </MenuItem>
      </DropdownMenu>
      {!isCommentNullInAllObjects(combineObjectArrays()) && (
        <Box
          sx={{
            pl: 2,
            width: '100%'
          }}
        >
          <Paper
            sx={{
              p: [0, 1, 1, 1],
              border: 'none'
            }}
          >
            <Typography sx={{ textAlign: 'left' }} variant="h4">
              {t('salarypage.subtitle.comments')}
            </Typography>
            {combineObjectArrays().map((item, index) => {
              return (
                item.payComponentHistory.current.comment !== null && (
                  <Typography key={index} sx={{ textAlign: 'left' }}>
                    {item.payComponentHistory.current.payrollCode +
                      ' (' +
                      item.payComponentHistory.current.description +
                      '): ' +
                      item.payComponentHistory.current.comment}
                  </Typography>
                )
              )
            })}
          </Paper>
        </Box>
      )}
    </>
  )
}

export default ContractsSalaryTab
