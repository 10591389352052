import {
 PARTNER_CONFIG_KEYS,
 useGetContractByIdQuery
} from '@epix-web-apps/core'
import DeBuropartner from './de-buropartner/de-buropartner'
import NlPhocus from './nl-phocus/nl-phocus'
import ItLdp from './it-ldp/it-ldp'
import CzAsbgroup from './cz-asbgroup'
import { EsAsec } from './es-asec'

/* eslint-disable-next-line */
export interface ProviderSpecificDetailsProps {
  contractId: string
}

export function ProviderSpecificDetails({ contractId }: ProviderSpecificDetailsProps) {
  const { data: getContractById } = useGetContractByIdQuery(
    {
      contractId: contractId || ''
    },
    {
      enabled: !!contractId
    }
  )
  const contract = getContractById?.contractById

  function renderProviderDetail(configKeys: string[]): boolean {
    return configKeys.some(e => e === contract?.icp.configurationKey);
  }

  return (
    <>
      { renderProviderDetail([PARTNER_CONFIG_KEYS.DE_BUROPARTNER, PARTNER_CONFIG_KEYS.DE_GENERIC]) && (
        <DeBuropartner contractId={contractId} />
      )}
      { renderProviderDetail([PARTNER_CONFIG_KEYS.NL_PHOCUS, PARTNER_CONFIG_KEYS.NL_GENERIC]) && (
        <NlPhocus contractId={contractId} />
      )}
      { renderProviderDetail([PARTNER_CONFIG_KEYS.IT_LDP, PARTNER_CONFIG_KEYS.IT_GENERIC]) && (
        <ItLdp contractId={contractId} />
      )}
      { renderProviderDetail([PARTNER_CONFIG_KEYS.CZ_ASBGROUP, PARTNER_CONFIG_KEYS.CZ_GENERIC]) && (
        <CzAsbgroup contractId={contractId} />
      )}
      { renderProviderDetail([PARTNER_CONFIG_KEYS.ES_ASEC, PARTNER_CONFIG_KEYS.ES_GENERIC]) && (
        <EsAsec contractId={contractId} />
      )}
    </>
  )
}

export default ProviderSpecificDetails
