import {
  CalendarDayModel,
  DEFAULT_BASED_ON_WORKSCHEDULE,
  DEFAULT_FULL_DAY,
  GraphqlError,
  numericString,
  OrderDirection,
  PersonalCalendarDayViewModel,
  useCreatePersonalCalendarDaysMutation,
  useDeletePersonalCalendarDayDocumentMutation,
  useDeletePersonalCalendarDayMutation,
  useGetAllPayrollCodesByEmployerIdQuery,
  useGetCalendarForDateRangeQuery,
  useGetContractByIdQuery,
  useUpdatePersonalCalendarDayMutation,
  useUploadPersonalCalendarDayFileMutation,
  FormSelectOption,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Divider, Radio, RadioGroup, Typography, Tooltip, IconButton, Box, Button, Grid, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { any, number, object, string, TypeOf } from 'zod'
import {
  ACCEPTED_UPLOAD_TYPES,
  FormFileUpload,
  FormGridLayout,
  FormRadioGroup,
  useFlyIn,
  FormActionButtons,
  FormErrorList,
  FormContainer,
  FormInput,
  FormSelect
} from '@epix-web-apps/ui'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

/* eslint-disable-next-line */
export interface AddCalendarEntryProps {
  start: Date
  end: Date
  contractId: string | undefined
  addMultipleCalendarEntries?: boolean
}

export function AddEditCalendarEntry({
  start,
  end,
  contractId,
  addMultipleCalendarEntries = false
}: AddCalendarEntryProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [comment, setComment] = useState(false)
  const { data: calendarData, refetch: refetchCalendarForDateRange } = useGetCalendarForDateRangeQuery({
    contractId: contractId || '',
    startDate: start,
    endDate: end
  })

  const [selectedCalendarEntry, setSelectedCalendarEntry] = useState(
    calendarData?.calendarDaysByContractId[0]?.personalCalendarDays[0]
  )

  const [addNewCalendarEntry, setAddNewCalendarEntry] = useState(false)
  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const createMutation = useCreatePersonalCalendarDaysMutation()
  const deleteMutation = useDeletePersonalCalendarDayMutation()
  const updateMutation = useUpdatePersonalCalendarDayMutation()
  const { mutateAsync: uploadFileAsync } = useUploadPersonalCalendarDayFileMutation()
  const deletePersonalCalendarDayDocument = useDeletePersonalCalendarDayDocumentMutation()

  const { data: getContractById } = useGetContractByIdQuery(
    {
      contractId: contractId || ''
    },
    {
      enabled: !!contractId
    }
  )
  const contract = getContractById?.contractById

  const { timeFrameOptions, timeOptions } = formTypeSelectOptions
  const { data: payrollCodesData } = useGetAllPayrollCodesByEmployerIdQuery(
    {
      employerId: contract?.employerId || '',
      offset: 0,
      limit: -1,
      orderDirection: OrderDirection.Asc,
      payrollCodeFilterModel: {
        showCalendarCodes: true
      }
    },
    {
      suspense: false
    }
  )

  const addCalendarEntrySchema = object({
    payrollCodeId: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    }).min(1, t('form.validation.payrollcoderequired')),
    timeFrameTypeKey: string({
      required_error: t('form.validation.timeframerequired'),
      invalid_type_error: t('form.validation.timeframerequired')
    }),
    timeTypeKey: string({
      required_error: t('form.validation.timetyperequired'),
      invalid_type_error: t('form.validation.timetyperequired')
    }),
    numberOfHours: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .min(0, t('form.validation.valuegreaterthen0'))
        .optional()
    ),
    comment: string().nullable().optional(),
    documents: any().optional().nullable()
  }).refine(data =>
    data.timeTypeKey !== DEFAULT_BASED_ON_WORKSCHEDULE
      ? data.numberOfHours
      : numericString(
          number({
            required_error: t('form.validation.valuerequired'),
            invalid_type_error: t('form.validation.valuemustbenumeric')
          }).positive({ message: t('form.validation.valuegreaterthen0') })
        )
  )

  const form = useForm<CreateCalendarEntryForm>({
    resolver: zodResolver(addCalendarEntrySchema),
    defaultValues: {
      payrollCodeId: selectedCalendarEntry?.payrollCodeId,
      numberOfHours: selectedCalendarEntry?.numberOfHours,
      timeFrameTypeKey: selectedCalendarEntry?.timeFrameType.key || DEFAULT_FULL_DAY,
      timeTypeKey: selectedCalendarEntry?.timeOrderedType.key || DEFAULT_BASED_ON_WORKSCHEDULE,
      comment: selectedCalendarEntry?.comment || '',
      documents: selectedCalendarEntry?.documents || []
    }
  })

  type CreateCalendarEntryForm = TypeOf<typeof addCalendarEntrySchema>
  const { closeFlyIn } = useFlyIn()
  const { control } = form

  const watchedTimeTypeKey = useWatch({ control, name: `timeTypeKey` })
  const handleOnSubmit = async (newCalendarEntry: CreateCalendarEntryForm) => {
    if (!selectedCalendarEntry) {
      await createMutation
        .mutateAsync({
          createPersonalCalendarDaysCommand: {
            contractId: contractId || '',
            personalCalendarDayTimeTypeKey: newCalendarEntry.timeTypeKey ?? DEFAULT_BASED_ON_WORKSCHEDULE,
            numberOfHours: newCalendarEntry.numberOfHours ? newCalendarEntry.numberOfHours : 0,
            payrollCodeId: newCalendarEntry.payrollCodeId,
            timeFrameTypeKey: newCalendarEntry.timeFrameTypeKey ?? DEFAULT_FULL_DAY,
            startDate: start,
            endDate: end,
            comment: newCalendarEntry.comment || ''
          }
        })
        .then(response => uploadFile(newCalendarEntry.documents, response.createPersonalCalendarDays))
        .then(() => {
          closeFlyIn()
          refetchCalendarForDateRange()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updatePersonalCalendarDayCommand: {
            personalCalendarDayId: selectedCalendarEntry.personalCalendarDayId || '',
            personalCalendarDayTimeTypeKey: newCalendarEntry.timeTypeKey ?? DEFAULT_BASED_ON_WORKSCHEDULE,
            numberOfHours: newCalendarEntry.numberOfHours ? newCalendarEntry.numberOfHours : 0,
            payrollCodeId: newCalendarEntry.payrollCodeId,
            timeFrameTypeKey: newCalendarEntry.timeFrameTypeKey ?? DEFAULT_FULL_DAY,
            comment: newCalendarEntry.comment || ''
          }
        })
        .then(response => uploadFile(newCalendarEntry.documents, [response.updatePersonalCalendarDay]))
        .then(() => {
          refetchCalendarForDateRange()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  const handleDelete = async () => {
    await deleteMutation
      .mutateAsync({
        deletePersonalCalendarDayCommand: {
          personalCalendarDayId: selectedCalendarEntry?.personalCalendarDayId || ''
        }
      })
      .finally(() => {
        refetchCalendarForDateRange()
        closeFlyIn()
      })
  }

  useEffect(() => {
    setSelectedCalendarEntry(calendarData?.calendarDaysByContractId[0]?.personalCalendarDays[0])
    if (calendarData?.calendarDaysByContractId[0]?.personalCalendarDays[0]?.comment) {
      setComment(true)
    }
  }, [calendarData])

  const extractAllPersonalCalendarDays = (data: any): PersonalCalendarDayViewModel[] => {
    const personalCalendarDays: PersonalCalendarDayViewModel[] = []
    if (data) {
      data.forEach((day: CalendarDayModel) => {
        personalCalendarDays.push(...day.personalCalendarDays)
      })
    }
    return personalCalendarDays
  }

  const personalCalendarDays = extractAllPersonalCalendarDays(calendarData?.calendarDaysByContractId)

  async function uploadFile(documents: any, personalCalendarDayIds: string[]) {
    if (documents) {
      await Promise.all(
        documents.map(
          (document: any) =>
            document.file &&
            uploadFileAsync({
              file: document.file,
              personalCalendarDayIds: personalCalendarDayIds
            })
        )
      )
    }
  }

  useEffect(() => {
    if (addMultipleCalendarEntries) {
      form.reset({
        payrollCodeId: '',
        timeFrameTypeKey: DEFAULT_FULL_DAY,
        timeTypeKey: DEFAULT_BASED_ON_WORKSCHEDULE,
        numberOfHours: null,
        comment: null
      })
    }
  }, [addMultipleCalendarEntries])

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      {!addMultipleCalendarEntries && (
        <>
          <Grid className="bold" container>
            <Grid xs={3.5} item>
              <p>{t('form.field.date')}</p>
            </Grid>
            <Grid xs={4} item>
              <p>{t('form.field.payrollCode')}</p>
            </Grid>
            <Grid xs={3} item>
              <p>{t('form.field.timeframetypekey')}</p>
            </Grid>
            <Grid xs={1.5} item>
              <p>{t('form.field.numberofhours')}</p>
            </Grid>
          </Grid>
          <Divider />
        </>
      )}

      {!addMultipleCalendarEntries && (
        <FormGridLayout hasBorderBottom>
          <Grid xs={12} sx={{ fontSize: '0.95em' }} item>
            {calendarData?.calendarDaysByContractId[0].personalCalendarDays && (
              <RadioGroup
                aria-labelledby="calendar-entries"
                value={selectedCalendarEntry?.personalCalendarDayId}
                onChange={(e, personalCalendarDayId) => {
                  const selectCalendarEntry = personalCalendarDays.find(
                    (x: PersonalCalendarDayViewModel) => x.personalCalendarDayId === personalCalendarDayId
                  )
                  setSelectedCalendarEntry(selectCalendarEntry)
                  form.reset({
                    payrollCodeId: selectCalendarEntry?.payrollCodeId,
                    timeFrameTypeKey: selectCalendarEntry?.timeFrameType.key,
                    timeTypeKey: selectCalendarEntry?.timeOrderedType.key,
                    numberOfHours: selectCalendarEntry?.numberOfHours,
                    comment: selectCalendarEntry?.comment
                  })
                }}
              >
                {personalCalendarDays.map((calendarEntry: PersonalCalendarDayViewModel, index: number) => (
                  <label
                    key={index}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      height: '3rem'
                    }}
                  >
                    <Grid xs={3.5} item>
                      <p>
                        <Radio
                          sx={{
                            padding: '0 0.25rem 0 0',
                            marginTop: '-0.2rem'
                          }}
                          size="small"
                          value={calendarEntry.personalCalendarDayId}
                        />
                        <span>{new Date(calendarEntry?.date).toLocaleDateString()}</span>
                      </p>
                    </Grid>
                    <Grid xs={4} item>
                      <p>
                        <span>{calendarEntry.payrollCodeUserFriendlyDescription}</span>
                      </p>
                    </Grid>
                    <Grid xs={3} item>
                      <p>{calendarEntry.timeFrameType.value}</p>
                    </Grid>
                    <Grid xs={1.5} item>
                      {calendarEntry.timeOrderedType.key === DEFAULT_BASED_ON_WORKSCHEDULE &&
                      calendarEntry.numberOfHours === 0 ? (
                        <Tooltip title={t('flyin.calendar-entry.basedonworkschedule')}>
                          <IconButton sx={{ m: 0, p: 0 }}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <p>{calendarEntry.numberOfHours}</p>
                      )}
                    </Grid>
                  </label>
                ))}
              </RadioGroup>
            )}
          </Grid>
          <Grid xs={12} item container justifyContent="space-between">
            <Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  form.reset({
                    payrollCodeId: '',
                    timeFrameTypeKey: DEFAULT_FULL_DAY,
                    timeTypeKey: DEFAULT_BASED_ON_WORKSCHEDULE,
                    numberOfHours: null,
                    comment: null
                  })
                  setAddNewCalendarEntry(true)
                  setSelectedCalendarEntry(undefined)
                }}
              >
                {t('flyin.calendarentry.addcalendarentry')}
              </Button>
            </Box>
            {personalCalendarDays && personalCalendarDays.length !== 0 && (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={handleDelete}
                  disabled={deleteMutation.isLoading}
                >
                  {t('flyin.calendarentry.delete-calendar-entry')}
                </Button>
              </Box>
            )}
          </Grid>
        </FormGridLayout>
      )}

      {(selectedCalendarEntry || addNewCalendarEntry || addMultipleCalendarEntries) && (
        <>
          <FormGridLayout hasPaddingTop>
            <FormSelect
              sx={12}
              name="payrollCodeId"
              label={`${t('form.field.type')} *`}
              options={payrollCodesData?.allPayrollCodesByEmployerId.data
                .filter(x => x.isCalendarEntry)
                .map(x => new FormSelectOption(x.id, x.code + ' - ' + x.userFriendlyDescription ?? x.description))}
            />

            <FormSelect
              sx={12}
              name="timeFrameTypeKey"
              label={`${t('form.field.timeframe')} *`}
              options={timeFrameOptions}
            />

            <Grid item xs={12}>
              <FormRadioGroup
                name="timeTypeKey"
                label={`${t('form.field.numberofhours')} *`}
                onChange={() =>
                  form.resetField('numberOfHours', {
                    defaultValue: undefined
                  })
                }
                options={timeOptions}
              />
            </Grid>

            <FormInput
              sx={12}
              name="numberOfHours"
              placeholder={`${watchedTimeTypeKey !== DEFAULT_BASED_ON_WORKSCHEDULE ? '0' : ''}`}
              label={`${t('form.field.numberofhours')} ${
                watchedTimeTypeKey !== DEFAULT_BASED_ON_WORKSCHEDULE ? '*' : ''
              }`}
            />

            {watchedTimeTypeKey === DEFAULT_BASED_ON_WORKSCHEDULE && (
              <Typography
                fontSize="0.95rem"
                paddingLeft="1rem"
                paddingTop="0.2rem"
                color={theme.palette.text.secondary}
                fontStyle="italic"
              >
                {t('flyin.calendarentry.optional-number-of-hours')}
              </Typography>
            )}

            <Grid xs={12} item>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setComment(!comment)
                }}
              >
                {t('flyin.calendarentry.addcomment')}
              </Button>
            </Grid>

            {comment && <FormInput sx={12} name="comment" multiline={true} label={t('form.field.comment')} />}

            <FormFileUpload
              sx={12}
              name="documents"
              buttonText={t('documentcomponent.button.title')}
              accept={[ACCEPTED_UPLOAD_TYPES.JPEG, ACCEPTED_UPLOAD_TYPES.PNG, ACCEPTED_UPLOAD_TYPES.PDF]}
              deleteDocument={documentId =>
                deletePersonalCalendarDayDocument.mutateAsync({
                  deletePersonalCalendarDayDocumentCommand: {
                    documentId: documentId,
                    personalCalendarDayId: selectedCalendarEntry?.personalCalendarDayId || ''
                  }
                })
              }
            />
          </FormGridLayout>

          <FormErrorList customErrors={backendErrors} />
          <FormActionButtons
            isMutating={createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading}
            onCancel={() => closeFlyIn()}
          />
        </>
      )}
    </FormContainer>
  )
}

export default AddEditCalendarEntry
