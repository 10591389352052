import {
  useGetMeQuery,
  useGetAllCurrenciesQuery,
  useUpdateMyCurrencyMutation,
  DEFAULT_FLYIN_WIDTH_SMALL
} from '@epix-web-apps/core'
import { Box, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { useFlyIn } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface ChangeCurrencyProps {}

export function ChangeCurrency(props: ChangeCurrencyProps) {
  const { t } = useTranslation()
  const { data: getMe, refetch: refetchMe } = useGetMeQuery()
  const { data: getAllCurrencies } = useGetAllCurrenciesQuery()
  const mutation = useUpdateMyCurrencyMutation()
  const { closeFlyIn } = useFlyIn()

  const handleClick = async (currency: string) => {
    await mutation.mutateAsync({ currency: currency }).then(() => {
      closeFlyIn()
      refetchMe()
    })
  }

  return (
    <Box sx={{ width: DEFAULT_FLYIN_WIDTH_SMALL }}>
      <Typography variant="h4">{t('flyin.changecurrency.title')}</Typography>
      <List>
        {getAllCurrencies?.currencies.map(item => (
          <React.Fragment key={item.name}>
            <ListItemButton selected={getMe?.me.currency === item.currency} onClick={() => handleClick(item.currency)}>
              <ListItemText primary={item.name} />
            </ListItemButton>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  )
}

export default ChangeCurrency
