import React from 'react'
import {
  DEFAULT_FLYIN_WIDTH_SMALL,
  useGetAllLocalesQuery,
  useGetMeQuery,
  useUpdateMyLocaleMutation
} from '@epix-web-apps/core'
import { Box, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material'
import { useFlyIn } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

/* eslint-disable-next-line */
export interface ChangeLocaleProps {}

export function ChangeLocale(props: ChangeLocaleProps) {
  const { t } = useTranslation()
  const { data: getAllLocales } = useGetAllLocalesQuery({
    ignoreTranslations: true,
    onlySupported: true,
    showInRespectiveLanguage: true
  })
  const mutation = useUpdateMyLocaleMutation()
  const { closeFlyIn } = useFlyIn()
  const { data: me } = useGetMeQuery()
  const { i18n } = useTranslation()
  const queryCache = useQueryClient()

  const handleClick = async (locale: string) => {
    await mutation
      .mutateAsync({ locale: locale })
      .then(updatedLocale => {
        sessionStorage.setItem('language', updatedLocale.updateMyLocale)
        i18n.changeLanguage(updatedLocale.updateMyLocale)
      })
      .finally(() => {
        queryCache.removeQueries()
        closeFlyIn()
      })
  }

  return (
    <Box sx={{ width: DEFAULT_FLYIN_WIDTH_SMALL }}>
      <Typography variant="h4">{t('flyin.changelanguage.title')}</Typography>
      <List>
        {getAllLocales?.locales.map(item => (
          <React.Fragment key={item.locale}>
            <ListItemButton selected={me?.me.locale.locale === item.locale} onClick={() => handleClick(item.locale)}>
              <ListItemText id={item.locale} primary={item.language} />
            </ListItemButton>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  )
}

export default ChangeLocale
