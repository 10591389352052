import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp, useGridApiContext } from '@mui/x-data-grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../data-table'
import {
  DocumentTypeModel,
  formTypeSelectOptions,
  OrderDirection,
  TypeModel,
  useGetAllEmployerDocumentTypesByEmployerIdQuery,
  useNavigateWithParams,
  useUpdateDocumentTypeMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, useFlyIn, Documents } from '@epix-web-apps/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TypeOf, object, string, boolean } from 'zod'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, Checkbox, Divider, IconButton, MenuItem, Typography } from '@mui/material'
import DropdownMenu from '../../dropdown-menu'
import { useParams } from 'react-router-dom'

const blockEmployeeAccessForFolderKeys = ['PAYROLL_CLOSURE', 'PAYCOMPONENT_UPDATE']

export enum FolderColumns {
  ISACTIVE = 'isActive',
  NAME = 'name',
  EMLOYEEACCESS = 'employeeAccess',
  ROWACTIONS = 'rowactions'
}

export type FolderListParams = {
  id: string
  employerid: string
}

type DocumentTypeRow = {
  id: string
  key: string
  isActive: boolean
  name: string
  employeeAccess: TypeModel
}

/* eslint-disable-next-line */
export interface FolderListProps {
  folders: Array<Omit<DocumentTypeModel, 'documents'>>
  employerId: string
  hideColumns?: Array<FolderColumns>
  editMode?: boolean
  goToDocumentTypeDetailPage?: (id: string) => string
  onChangesSaved?: () => void
  title?: string
  editFlyin?: (documentTypeId: string) => React.ReactNode
  editAccessFlyin?: (documentType: string) => React.ReactNode
}

export function FolderList({
  folders,
  employerId,
  hideColumns = [],
  editMode = false,
  goToDocumentTypeDetailPage,
  onChangesSaved,
  title,
  editFlyin,
  editAccessFlyin
}: FolderListProps) {
  const { t } = useTranslation()
  const { documentAccessLevelTypeOptions } = formTypeSelectOptions
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedDocumentType, setClickedDocumentType] = useState<DocumentTypeRow | null>(null)
  const [tableData, setTableData] = useState<GridRowsProp>(folders)
  const { openFlyIn, closeFlyIn } = useFlyIn()
  const navigate = useNavigateWithParams()
  const params = useParams<FolderListParams>()
  const updateMutation = useUpdateDocumentTypeMutation()

  const { refetch } = useGetAllEmployerDocumentTypesByEmployerIdQuery({
    id: employerId || ''
  })

  const documentTypeSchema = object({
    id: string({
      required_error: t('form.validation.idrequired'),
      invalid_type_error: t('form.validation.idrequired')
    }).min(1, t('form.validation.idrequired')),
    key: string().nullable(),
    active: boolean(),
    name: string(),
    employeeAccessLevelKey: string({
      required_error: t('form.validation.employeeaccesslevelrequired'),
      invalid_type_error: t('form.validation.employeeaccesslevelrequired')
    }).min(1, t('form.validation.employeeaccesslevelrequired'))
  })

  const documentTypesSchema = object({
    documentTypeRows: documentTypeSchema.array()
  })

  type DocumentTypeForm = TypeOf<typeof documentTypeSchema>
  type DocumentTypesForm = TypeOf<typeof documentTypesSchema>
  const form = useForm<DocumentTypesForm>({
    resolver: zodResolver(documentTypesSchema),
    defaultValues: {
      documentTypeRows:
        folders.map(
          x =>
            ({
              id: x.id,
              key: x.key,
              active: x.active,
              name: x.name,
              employeeAccessLevelKey: x.employeeAccessLevel.key
            } as DocumentTypeForm)
        ) || []
    }
  })

  const handleIsActiveChange = (documentTypeId: GridRowId, isChecked: boolean) => {
    const index = form.getValues('documentTypeRows').findIndex(x => x.id === documentTypeId)
    form.setValue(`documentTypeRows.${index}.active`, isChecked)
  }

  const handleEmployeeAccessLevelChanged = (documentTypeId: GridRowId, value: string) => {
    const index = form.getValues('documentTypeRows').findIndex(x => x.id === documentTypeId)
    form.setValue(`documentTypeRows.${index}.employeeAccessLevelKey`, value)
  }

  const ActiveCell = (rowParams: GridRenderCellParams) => {
    if (editMode) {
      const checkboxValue = form.getValues('documentTypeRows').find(x => x.id === rowParams.id)?.active
      return (
        <Checkbox checked={checkboxValue} onChange={(e, isChecked) => handleIsActiveChange(rowParams.id, isChecked)} />
      )
    }
    return rowParams.value ? <DoneIcon /> : <CloseIcon />
  }

  const AccessLevelDropDown = (params: GridRenderCellParams) => {
    const ref = useGridApiContext()
    const selectedAccessLevelLabel = documentAccessLevelTypeOptions.find(x => x.id === params.value)?.label

    if (!editMode) return <Box>{selectedAccessLevelLabel}</Box>

    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        onClick={e => {
          e.stopPropagation() // to not select row
          ref.current.startCellEditMode({
            id: params.id,
            field: params.field
          })
        }}
      >
        <Box>{selectedAccessLevelLabel}</Box>
        <IconButton size="small" sx={{ padding: 0 }} tabIndex={-1}>
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
    )
  }

  const rowActions = useCallback(
    (rowParams: GridRenderCellParams) => (
      <>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            if (rowParams.row.id) {
              setClickedDocumentType(rowParams.row)
            }
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate(
              Documents.DOCUMENTS_ICP_ID_EMPLOYER_ID_DOCUMENTTYPE_ID(
                params.id || '',
                employerId,
                rowParams.row.id || ''
              )
            )
          }}
          aria-label="row details"
          aria-controls="details-row"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </>
    ),
    []
  )

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: FolderColumns.ISACTIVE,
        headerName: t('documenttypes.datatable.column.isactive'),
        width: 60,
        sortable: false,
        hide: hideColumns.includes(FolderColumns.ISACTIVE),
        renderCell: ActiveCell
      },
      {
        field: FolderColumns.NAME,
        headerName: t('documenttypes.datatable.column.name'),
        flex: 1,
        sortable: false,
        hide: hideColumns.includes(FolderColumns.NAME)
      },
      {
        field: FolderColumns.EMLOYEEACCESS,
        headerName: t('documenttypes.datatable.column.employeeAccess'),
        flex: 1,
        sortable: false,
        hide: hideColumns.includes(FolderColumns.EMLOYEEACCESS),
        type: 'singleSelect',
        valueGetter: ({ value }) => (typeof value === 'string' ? value : value?.key),
        valueParser: (value: any, row: any) => {
          handleEmployeeAccessLevelChanged(row.id, value)
          return value
        },
        valueOptions: documentAccessLevelTypeOptions.map(x => ({ value: x.id, label: x.label })),
        editable: editMode,
        renderCell: AccessLevelDropDown
      },
      {
        field: FolderColumns.ROWACTIONS,
        headerName: '',
        sortable: false,
        editable: false,
        hide: hideColumns.includes(FolderColumns.ROWACTIONS),
        renderCell: rowActions
      }
    ],
    [documentAccessLevelTypeOptions]
  )

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (folders) {
      setTableData(
        folders.map(row => {
          return {
            id: row.id,
            isActive: row.active,
            name: row.name,
            employeeAccess: row.employeeAccessLevel.key,
            key: row.key
          }
        })
      )
    }
  }, [folders])

  const handleOnSubmit = async (documentTypes: DocumentTypesForm) => {
    await Promise.all(
      documentTypes.documentTypeRows.map(async documentType => {
        await updateMutation.mutateAsync({
          updateDocumentTypeCommand: {
            id: documentType.id,
            name: documentType.name,
            active: documentType.active,
            employeeAccessLevelTypeKey: documentType.employeeAccessLevelKey,
            translations: []
          }
        })
      })
    ).finally(() => {
      closeFlyIn()
      onChangesSaved && onChangesSaved()
    })
  }

  const filterBar = (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: '0.6rem'
        }}
      >
        <Typography m={0} variant="h4">
          {title}
        </Typography>
      </Box>
      <Divider />
    </>
  )

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)} sx={editMode ? { flexGrow: 1 } : {}}>
      <DataTable
        autoHeight
        data={tableData}
        onRowClick={params =>
          params.row.id && goToDocumentTypeDetailPage && navigate(goToDocumentTypeDetailPage(params.row.id))
        }
        filterBarElement={title ? filterBar : <></>}
        columns={columns}
        hideFooter={true}
        isLoading={false}
        defaultSortModel={sortModel}
      />
      {editMode && <FormActionButtons isMutating={false} onCancel={() => closeFlyIn()} />}
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {goToDocumentTypeDetailPage && (
          <MenuItem
            onClick={() =>
              clickedDocumentType &&
              goToDocumentTypeDetailPage &&
              navigate(goToDocumentTypeDetailPage(clickedDocumentType.id))
            }
          >
            {t('peoplepage.list.row.menu.viewdetails')}
          </MenuItem>
        )}
        {editFlyin && (
          <MenuItem
            onClick={() => {
              openFlyIn({
                content: editFlyin && editFlyin(clickedDocumentType?.id || ''),
                callbackAfterClose: () => () => {
                  refetch()
                  onChangesSaved && onChangesSaved()
                }
              })
            }}
          >
            {t('common.edit')}
          </MenuItem>
        )}
        {editAccessFlyin &&
          clickedDocumentType?.isActive &&
          !blockEmployeeAccessForFolderKeys.includes(clickedDocumentType.key) && (
            <MenuItem
              onClick={() => {
                openFlyIn({
                  content: editAccessFlyin && editAccessFlyin(clickedDocumentType?.id || ''),
                  callbackAfterClose: () => () => {
                    refetch()
                    onChangesSaved && onChangesSaved()
                  }
                })
              }}
            >
              {t('common.editaccess')}
            </MenuItem>
          )}
      </DropdownMenu>
    </FormContainer>
  )
}

export default FolderList
