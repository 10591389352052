import { PORT, SUBDOMAIN_STATE_PREFIX } from '../../../../msal-config'
import { environment } from '../../../../environments/environment'
import { EpixLoadingPage, GeneralRoutes } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AuthRedirectPageProps {}

export function AuthRedirectPage(props: AuthRedirectPageProps) {
  const urlParams = new URLSearchParams(window.location.search)
  const stateQueryParam = urlParams.get('state')
  const tenant = window.location.hash.split(SUBDOMAIN_STATE_PREFIX)?.[1]?.split('&')?.[0] ||
    stateQueryParam?.replace(SUBDOMAIN_STATE_PREFIX, '') || ''

  let redirectUrl = `${window.location.protocol}//`
  if (tenant !== '') {
    sessionStorage.setItem('tenant', tenant)
    redirectUrl += `${tenant}.${environment.auth.redirectDomain}${PORT}${window.location.hash}`
  } else {
    redirectUrl += `${environment.auth.redirectDomain}${PORT}${GeneralRoutes.NOTAUTHORIZED}`
  }
  window.location.replace(redirectUrl)

  return <EpixLoadingPage message="Redirecting to your epix environment..." />
}

export default AuthRedirectPage
