import { Box, Typography } from '@mui/material'
import { FolderColumns, FolderList } from '../folder-list'
import { useTranslation } from 'react-i18next'
import { DEFAULT_FLYIN_WIDTH, useGetAllContractDocumentTypesByEmployerIdQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface EditEmployeeDocumentTypesProps {}

export function EditEmployeeDocumentTypes({ employerId }: { employerId: string }) {
  const { t } = useTranslation()
  const { data: getAllContractDocumentTypes, refetch: refetchAllContractDocumentTypes } =
    useGetAllContractDocumentTypesByEmployerIdQuery({
      id: employerId,
      activeOnly: false
    })

  return (
    <Box sx={{ width: DEFAULT_FLYIN_WIDTH }}>
      <Typography variant="h4">{t('flyin.editemployeedocumenttypes.title')}</Typography>
      <FolderList
        editMode
        employerId={employerId}
        hideColumns={[FolderColumns.ROWACTIONS]}
        folders={getAllContractDocumentTypes?.allContractDocumentTypesByEmployerId || []}
        onChangesSaved={refetchAllContractDocumentTypes}
      />
    </Box>
  )
}

export default EditEmployeeDocumentTypes
