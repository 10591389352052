import { Box, Tabs } from '@mui/material'
import { Suspense, useEffect, useState } from 'react'
import { ContractsOverviewTab } from '../contracts-overview-tab'
import { ContractsSalaryTab } from '../contracts-salary-tab'
import {
  OrderDirection,
  isManagedByEpixAndIcpUniqueCodeMatches,
  useGetAllValidationErrorsByContractIdQuery,
  useGetCalculatedDataByContractIdQuery,
  useGetContractByIdQuery
} from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { LoadingOverlay } from '@epix-web-apps/ui'
import { TabPanel, a11yProps } from '@epix-web-apps/ui'
import ContractCalendarTab from '../contract-calendar-tab'
import ProviderSpecificDetails from '../provider-specific-details/provider-specific-details'
import ContractValidationerrorsTab from '../contract-validationerrors-tab/contract-validationerrors-tab'
import ContractCalculatedDataTab from '../contract-calculated-data-tab/contract-calculated-data-tab'
import { ContractsSelfServiceTab } from '../contracts-selfservice-tab'
import { IconTab } from '../../tab-components/tab/icon-tab'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { ContractsDocumentTab } from '../contracts-document-tab'

export enum ContractTabs {
  OVERVIEW,
  ICP,
  SALARY,
  CALENDAR,
  DOCUMENTS,
  VALIDATION_ERRORS,
  CALCULATED_DATA,
  SELFSERVICE
}

/* eslint-disable-next-line */
export interface ContractTabGeneralProps {}

export function ContractTabGeneral(props: ContractTabGeneralProps) {
  const { t } = useTranslation()
  const params = useParams<ContractDetailPageParams>()
  const [searchParams, setSearchParams] = useSearchParams()
  const tabIndex = parseInt(searchParams.get('tabIndex') ?? '0')

  const { data: getContractById } = useGetContractByIdQuery(
    {
      contractId: params?.contractid || ''
    },
    {
      enabled: !!params?.contractid
    }
  )
  const contract = getContractById?.contractById
  const { data: validations } = useGetAllValidationErrorsByContractIdQuery({
    contractId: params.contractid || '',
    offset: 0,
    limit: -1,
    sortByProperty: null,
    orderDirection: OrderDirection.Asc
  })
  const { data: calculatedData } = useGetCalculatedDataByContractIdQuery({
    contractId: params.contractid || '',
    filterModel: {
      payrollPeriodStartDate: null,
      payrollPeriodEndDate: null,
      calculationStartDate: null,
      calculationEndDate: null,
      valueTypeKey: null
    },
    limit: 1
  })
  const noErrors = validations?.allValidationErrorsByContractId.totalCount === 0
  const noCalculatedData = calculatedData?.calculatedDataByContractId.calculatedDataDetails.length === 0
  const hasSelfService = getContractById?.contractById.payGroup?.hasSelfService ?? false

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: '100%' }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs"
          onChange={(_, index) => {
            searchParams.set('tabIndex', `${index}`)
            setSearchParams(searchParams, { replace: true })
          }}
          value={tabIndex}
        >
          <IconTab
            label={t('contractdetailpage.tab.overview')}
            icon={<WorkOutlineOutlinedIcon />}
            {...a11yProps(ContractTabs.OVERVIEW)}
          />
          <IconTab
            label={contract?.icp.code}
            icon={<LanguageOutlinedIcon />}
            {...a11yProps(ContractTabs.ICP)}
            sx={{ display: isManagedByEpixAndIcpUniqueCodeMatches(contract) ? '' : 'none' }}
          />
          <IconTab
            label={t('contractdetailpage.tab.salary')}
            icon={<EuroSymbolOutlinedIcon />}
            {...a11yProps(ContractTabs.SALARY)}
            sx={{ display: contract?.payGroup?.hasPayComponentManagement ? '' : 'none' }}
          />
          <IconTab
            label={t('contractdetailpage.tab.calendar')}
            icon={<EventOutlinedIcon />}
            {...a11yProps(ContractTabs.CALENDAR)}
            sx={{ display: contract?.payGroup?.hasCalendarManagement ? '' : 'none' }}
          />
          <IconTab
            label={t('contractdetailpage.tab.documents')}
            icon={<FolderOutlinedIcon />}
            {...a11yProps(ContractTabs.DOCUMENTS)}
            sx={{ display: contract?.payGroup?.hasDocumentManagement ? '' : 'none' }}
          />
          <IconTab
            label={t('contractdetailpage.tab.validationerrors')}
            icon={<InfoOutlinedIcon />}
            {...a11yProps(ContractTabs.VALIDATION_ERRORS)}
            sx={{ display: !noErrors ? '' : 'none' }}
          />
          <IconTab
            label={t('contractdetailpage.tab.calculateddata')}
            icon={<DashboardOutlinedIcon />}
            {...a11yProps(ContractTabs.CALCULATED_DATA)}
            sx={{ display: !noCalculatedData ? '' : 'none' }}
          />
          <IconTab
            label={t('contractdetailpage.tab.selfservice')}
            icon={<PhoneAndroidOutlinedIcon />}
            {...a11yProps(ContractTabs.SELFSERVICE)}
            sx={{ display: hasSelfService ? '' : 'none' }}
          />
        </Tabs>
      </Box>

      <TabPanel value={tabIndex} index={ContractTabs.OVERVIEW}>
        <Suspense fallback={<LoadingOverlay />}>
          <ContractsOverviewTab />
        </Suspense>
      </TabPanel>

      {isManagedByEpixAndIcpUniqueCodeMatches(contract) && params?.contractid && (
        <TabPanel value={tabIndex} index={ContractTabs.ICP}>
          <Suspense fallback={<LoadingOverlay />}>
            <ProviderSpecificDetails contractId={params.contractid} />
          </Suspense>
        </TabPanel>
      )}

      {contract?.payGroup?.hasPayComponentManagement && (
        <TabPanel value={tabIndex} index={ContractTabs.SALARY}>
          <Suspense fallback={<LoadingOverlay />}>
            <ContractsSalaryTab />
          </Suspense>
        </TabPanel>
      )}
      {contract?.payGroup?.hasCalendarManagement && (
        <TabPanel value={tabIndex} index={ContractTabs.CALENDAR}>
          <Suspense fallback={<LoadingOverlay />}>
            <ContractCalendarTab contractId={params?.contractid} />
          </Suspense>
        </TabPanel>
      )}
      {contract?.payGroup?.hasDocumentManagement && (
        <TabPanel value={tabIndex} index={ContractTabs.DOCUMENTS}>
          <Suspense fallback={<LoadingOverlay />}>
            <ContractsDocumentTab contractId={params?.contractid} />
          </Suspense>
        </TabPanel>
      )}
      {!noErrors && (
        <TabPanel value={tabIndex} index={ContractTabs.VALIDATION_ERRORS}>
          <Suspense fallback={<LoadingOverlay />}>
            <ContractValidationerrorsTab contractId={params?.contractid} />
          </Suspense>
        </TabPanel>
      )}
      {!noCalculatedData && (
        <TabPanel value={tabIndex} index={ContractTabs.CALCULATED_DATA}>
          <Suspense fallback={<LoadingOverlay />}>
            <ContractCalculatedDataTab contractId={params?.contractid} />
          </Suspense>
        </TabPanel>
      )}
      {hasSelfService && (
        <TabPanel value={tabIndex} index={ContractTabs.SELFSERVICE}>
          <Suspense fallback={<LoadingOverlay />}>
            <ContractsSelfServiceTab />
          </Suspense>
        </TabPanel>
      )}
    </Box>
  )
}

export default ContractTabGeneral
