import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout } from '@epix-web-apps/ui'
import { Box, Typography } from '@mui/material'
import { FormSwitch } from '../../form-components/form-switch'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  TenantFeature,
  TenantFeatureModel,
  useGetTenantConfigurationQuery,
  useUpdateTenantFeaturesMutation
} from '@epix-web-apps/core'
import { useForm, useWatch } from 'react-hook-form'
import { boolean, object, TypeOf } from 'zod'

function getOrDefault(tenantFeatures: TenantFeatureModel[] | undefined, feature: TenantFeature): boolean {
  return tenantFeatures?.find(f => f.feature === feature)?.isEnabled ?? false
}

export function TenantFeaturesForm() {
  const { t } = useTranslation()
  const updatemutation = useUpdateTenantFeaturesMutation()
  const { data: getFeatures, refetch: refetchFeatures } = useGetTenantConfigurationQuery()

  const editFeaturesForm = object({
    calculatedByEpix: boolean(),
    hasPayComponentManagement: boolean(),
    hasCalendarManagement: boolean(),
    hasDocumentManagement: boolean(),
    hasSelfService: boolean(),
    hasCompanyCars: boolean()
  })
  type EditFeaturesForm = TypeOf<typeof editFeaturesForm>

  const featuresForm = useForm<EditFeaturesForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      calculatedByEpix: getOrDefault(getFeatures?.tenantConfiguration, TenantFeature.CalculatedByEpix),
      hasCalendarManagement: getOrDefault(getFeatures?.tenantConfiguration, TenantFeature.HasCalendarManagement),
      hasDocumentManagement: getOrDefault(getFeatures?.tenantConfiguration, TenantFeature.HasDocumentManagement),
      hasPayComponentManagement: getOrDefault(
        getFeatures?.tenantConfiguration,
        TenantFeature.HasPayComponentManagement
      ),
      hasSelfService: getOrDefault(getFeatures?.tenantConfiguration, TenantFeature.HasSelfService),
      hasCompanyCars: getOrDefault(getFeatures?.tenantConfiguration, TenantFeature.HasCompanyCars)
    },
    resolver: zodResolver(editFeaturesForm)
  })

  async function handleOnSubmit(newFeatures: EditFeaturesForm) {
    await updatemutation
      .mutateAsync({
        updateTenantFeaturesCommand: {
          calculatedByEpix: newFeatures.calculatedByEpix,
          hasCalendarManagement: newFeatures.hasCalendarManagement,
          hasCompanyCars: newFeatures.hasCompanyCars,
          hasDocumentManagement: newFeatures.hasDocumentManagement,
          hasPayComponentManagement: newFeatures.hasPayComponentManagement,
          hasSelfService: newFeatures.hasSelfService
        }
      })
      .then(() => {
        refetchFeatures()
      })
  }

  const { control } = featuresForm
  const watchCalendarManagement = useWatch({
    control,
    name: 'hasCalendarManagement'
  })

  return (
    <>
      <Typography variant="h3" color="textSecondary">
        {t('tenantconfigurationpage.tenantfeatures.title')}
      </Typography>
      <FormContainer form={featuresForm} onSubmit={featuresForm.handleSubmit(handleOnSubmit)}>
        <FormGridLayout>
          <FormSwitch
            sx={12}
            name="calculatedByEpix"
            onChange={e => {
              if (e) {
                featuresForm.resetField('hasPayComponentManagement', {
                  defaultValue: true
                })
                featuresForm.resetField('hasCalendarManagement', { defaultValue: true })
                featuresForm.resetField('hasSelfService', { defaultValue: true })
              }
            }}
            label={t('form.field.payrollviaepix')}
          />

          <FormSwitch sx={12} name="hasPayComponentManagement" label={t('form.field.paycomponentmanagement')} />

          <FormSwitch
            sx={12}
            name="hasCalendarManagement"
            onChange={e => {
              if (!e) {
                featuresForm.resetField('hasSelfService', { defaultValue: false })
              }
            }}
            label={t('form.field.calendarmanagement')}
          />

          <Box ml={8}>
            <FormSwitch
              sx={12}
              disabled={!watchCalendarManagement}
              name="hasSelfService"
              label={t('form.field.selfservice.hasselfservice')}
            />
          </Box>

          <FormSwitch sx={12} name="hasCompanyCars" label={t('form.field.hascompanycars')} />

          <FormSwitch sx={12} name="hasDocumentManagement" label={t('form.field.hasdocumentmanagement')} />
        </FormGridLayout>
        <FormErrorList />
        <FormActionButtons isMutating={updatemutation.isLoading} />
      </FormContainer>
    </>
  )
}
