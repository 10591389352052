import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { DEFAULT_FLYIN_WIDTH, useGetTeamMemberEmailsByTeamIdQuery } from '@epix-web-apps/core'

export interface MailingListFlyInProps {
  teamId?: string
}

export function MailingListFlyIn({ teamId }: MailingListFlyInProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [enabled, setEnabled] = useState(false)
  const [radioValues, setRadioValues] = useState({
    includeChildren: false,
    workEmail: true,
    includeWithoutEmail: false
  })

  const getTeamMemberEmailsByTeamIdQuery = useGetTeamMemberEmailsByTeamIdQuery(
    {
      teamId: teamId || '',
      includeChildren: radioValues.includeChildren,
      workEmail: radioValues.workEmail
    },
    { enabled: enabled }
  )

  useEffect(() => {
    if (
      enabled &&
      getTeamMemberEmailsByTeamIdQuery &&
      getTeamMemberEmailsByTeamIdQuery.data?.allTeamMemberEmailsByTeamId
    ) {
      const teamMails = getTeamMemberEmailsByTeamIdQuery.data?.allTeamMemberEmailsByTeamId
        .map(m => (radioValues.includeWithoutEmail ? m.email || `[${m.name}]` : m.email))
        .filter(e => e)
        .join('; ')

      copyToClipboard(teamMails)
      setEnabled(false)
      closeFlyIn()
    }
  }, [getTeamMemberEmailsByTeamIdQuery])

  async function copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text)
  }

  function handleChange(value: string, radioGroup: string) {
    setRadioValues({ ...radioValues, [radioGroup]: value === 'true' })
  }

  return (
    <Box sx={{ width: DEFAULT_FLYIN_WIDTH }}>
      <Typography variant="h4">{t('teams.maillist.getmaillist')}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <RadioGroup value={radioValues.includeChildren} onChange={(e, value) => handleChange(value, 'includeChildren')}>
          <FormControlLabel value={false} label={t('teams.maillist.onlythisteam')} control={<Radio />} />
          <FormControlLabel value={true} label={t('teams.maillist.allchildteams')} control={<Radio />} />
        </RadioGroup>
        <RadioGroup value={radioValues.workEmail} onChange={(e, value) => handleChange(value, 'workEmail')}>
          <FormControlLabel value={true} label={t('teams.maillist.workemail')} control={<Radio />} />
          <FormControlLabel value={false} label={t('teams.maillist.personalemail')} control={<Radio />} />
        </RadioGroup>
        <RadioGroup
          value={radioValues.includeWithoutEmail}
          onChange={(e, value) => handleChange(value, 'includeWithoutEmail')}
        >
          <FormControlLabel value={false} label={t('teams.maillist.includewithemail')} control={<Radio />} />
          <FormControlLabel value={true} label={t('teams.maillist.includewithoutemail')} control={<Radio />} />
        </RadioGroup>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button variant="contained" onClick={() => setEnabled(true)}>
            {t('teams.maillist.copytoclipboard')}
          </Button>
          <Button variant="outlined" onClick={closeFlyIn}>
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default MailingListFlyIn
