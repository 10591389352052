import { FEATURE, Module, ROLE, useCanAccess } from '@epix-web-apps/core'
import { ReactNode } from 'react'
import { NotAuthorizedPage } from '../../pages/not-authorized-page'

/* eslint-disable-next-line */
export interface IsAuthorizedProps {
  children: ReactNode
  roles?: ROLE[]
  feature?: FEATURE
  module?: Module
}

export function IsAuthorized({ children, roles, feature, module }: IsAuthorizedProps) {
  const canAccess = useCanAccess(roles ?? [], feature, module)

  return canAccess ? <>{children}</> : <NotAuthorizedPage />
}

export function CanView({ children, roles, feature, module }: IsAuthorizedProps) {
  const canAccess = useCanAccess(roles ?? [], feature, module)

  return canAccess ? <>{children}</> : null
}

export default IsAuthorized
